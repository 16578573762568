import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import Settings from "../../../AppSettings";
import ServiceSurvey from '../../../services/survey';
import ServiceQuestion from '../../../services/question';
import CustomRadarChart from '../chart/radarchart';

import { RadialChart } from 'react-vis';
import { Button, CheckBox, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography } from '../../ui/core';
import ReactDOMServer from 'react-dom/server'
export default function Generate({ survey, onClose }) {
    const [charts, setCharts] = useState([])
    const [postData, setPostData] = useState();

    const [showSummary, setShowSummery] = useState(true);
    const [showGeneralResults, setShowGeneralResults] = useState(true);
    const [showDetailResults, setShowDetailResults] = useState(true);
    const [showAttachmentPerQuestion, setShowAttachmentPerQuestion] = useState(true);
    const [showEvaluations,setShowEvaluations] = useState(true);
    const [showFindings,setShowFindings] = useState(true);

    const ref = useRef();

    const locationAPI = Settings.getLocationOpenDocumentAPI();

    const downloadBase64File = (base64Data, fileName) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const createCharts = async () => {
        let questions = [];
        try { questions = await ServiceQuestion.getForSurvey(survey._id); } catch (ex) { console.log(ex) }

        let scores = await ServiceSurvey.getScores(survey._id);
        let scoresDetail = await ServiceSurvey.getScoresDetail(survey._id);

        let opentext = scoresDetail.openText || [];
        let closedValues = scoresDetail.closedValues || [];
        let openValues = scoresDetail.openValues || [];

        let mainProcessScore = scores.mainProcessScore || [];
        let subProcessScore = scores.subProcessScore || [];



        let tmpCharts = [];
        //main process score chart
        survey.mainProcesses.filter(p => { return p.active === true }).forEach(mainProcess => {
            let tmpScores = [];

            if (mainProcess && mainProcess.subProcesses) {

                for (let t = 0; t < mainProcess.subProcesses.length; t++) {

                    if (mainProcess.subProcesses[t].active === true) {

                        let tmpScore = {};
                        tmpScore._id = mainProcess.subProcesses[t]._id;
                        tmpScore.title = mainProcess.subProcesses[t].title;
                        tmpScore.score = 0;
                        let score = subProcessScore.find(i => { return i._id === mainProcess.subProcesses[t]._id });
                        if (score) {
                            tmpScore.score = score.perc;
                            tmpScore.color = ServiceSurvey.getColorFromRange(survey.colorRange, score.perc);
                        }
                        tmpScore.max = 100;
                        tmpScores.push(tmpScore);
                    }
                }
            }
            let chart = <CustomRadarChart id={mainProcess._id} key={mainProcess._id} scores={tmpScores} colorRange={survey.colorRange} />

            tmpCharts.push(chart);
        })

        //question chart
        questions.forEach(question => {
            if(question.options){
                question.options = question.options.filter(q=>{return q.active===true})
                scores = closedValues.filter(s => { return s.value >= 0 && s.question == question._id });
              
                if (scores.length === 0) {
                    return null;
                }
                let data = [];
                let bri = 1;
                let briSteps = 1 / question.options.filter(s => { return s.score >= 0 }).length;
                if (question.options) {
                    for (let i = 0; i < question.options.length; i++) {
                        if (question.options[i].score >= 0) {
                            ///console.log( ServiceColor.changeHue("#00698f",hue))
                            let foundScores = scores.filter(s => { return s.value === question.options[i].score })
                            data.push({ id: question.options[i]._id, angle: foundScores.length, style: { fill: `rgba(0,105,143,${bri})`, stroke: `rgba(0,105,143,${bri})` }, colorType: "literal", label: (Math.round(((100 / scores.length) * foundScores.length) * 10) / 10) + '%', text: question.options[i].text })
                            bri = bri - briSteps;//0.2
                        }
                    }
                }
    
                question.chartData = data;
                let chart = <div id={question._id} key={question._id} ><RadialChart showLabels={true} data={data} labelsRadiusMultiplier={0.9} labelsStyle={{ fontSize: "11px", fill: "white", backgroundColor: "black" }} width={200} height={200} /></div>
                tmpCharts.push(chart);
            }
          
        })

        setCharts(tmpCharts);


        let postData = {
            title: survey.title || "",
            colorRange: survey.colorRange,
            subTitle: "",
            author: "",
            showAttachmentPerQuestion: true,
            intro: "In dit document...",
            summary: "Samengevat is het .",
            mainProcesses: survey.mainProcesses.filter(p => { return p.active === true }),
            // mainProcesses:[{code:"1",title:"Process 1",description:"Beschrijving bij process 1"},{code:"2",title:"Process 2",image:""}],
            // subProcesses: [{ code: "a", title: "Sub Process A", mainProcessCode: "1" }, { code: "b", title: "Sub Process b", mainProcessCode: "1" }],
            mainProcessScore: mainProcessScore,// [{ _id: "1", perc: 60 }, { _id: "2", perc: 70 }],
            subProcessScore: subProcessScore,// [{ _id: "a", perc: 55 }, { _id: "b", perc: 79 }],
            questions: questions,//[{_id:"1",question:"Is opgeschaald op ..",sort:"1"},{_id:"2",question:"Is afgeschaald op ..",sort:"2",image:""}],
            opentext: opentext,//[{question:"1",openText:"De waarneming is .."},{question:"2",openText:"De bevinding is .."}],//waarnemingen
            closedValues: closedValues,//[{question:"1",perc:80},{question:"2",perc:70}]
            openValues:openValues
        }
        setPostData(postData);
    }

    useEffect(() => {
        createCharts();
    }, [])

    const handleDownload = async () => {
        if (postData && ref.current) {
            let tmpPostData = Object.assign({}, postData);
            tmpPostData.showSummary = showSummary;
            tmpPostData.showGeneralResults = showGeneralResults;
            tmpPostData.showDetailResults = showDetailResults;
            tmpPostData.showEvaluations = showEvaluations;
            tmpPostData.showFindings = showFindings;
            tmpPostData.showAttachmentPerQuestion = showAttachmentPerQuestion;
            tmpPostData.mainProcesses.forEach(process => {
                let chart = Array.from(ref.current.children).find(child => { return child.id == process._id });
                if (chart) {
                    process.image = "data:image/svg+xml;base64," + window.btoa(chart.outerHTML);
                }
            })
            tmpPostData.questions.forEach(question => {
                let chart = Array.from(ref.current.children).find(child => { return child.id == question._id });
                if (chart) {
                    question.image = "data:image/svg+xml;base64," + window.btoa(chart.getElementsByTagName("svg")[0].outerHTML);
                }
            })
            axios.post(locationAPI, tmpPostData, {})
                .then(res => {
                    downloadBase64File(res.data, "rapport.docx")
                }, err => {
                    console.log(err);
                })
        }
    }
    return (
        <Dialog onClose={onClose}>
            <DialogTitle ><Typography component="h1">Rapport generator</Typography></DialogTitle>
            <DialogContent>
                Toevoegen aan rapport:<br /><br />
                <CheckBox label={"Samenvatting"} checked={showSummary} onChange={() => { setShowSummery(!showSummary) }}></CheckBox>

                <CheckBox label={"Resultaten algemeen"} checked={showGeneralResults} onChange={() => { setShowGeneralResults(!showGeneralResults) }}></CheckBox>

                <CheckBox label={"Resultaten gedetaileerd"} checked={showDetailResults} onChange={() => { setShowDetailResults(!showDetailResults) }}></CheckBox>

                <CheckBox label={"Resultaten evaluatie gesprekken"} checked={showEvaluations} onChange={() => { setShowEvaluations(!showEvaluations) }}></CheckBox>

                <CheckBox label={"Bevindingen"} checked={showFindings} onChange={() => { setShowFindings(!showFindings) }}></CheckBox>

                <CheckBox label={"Bijlage - beantwoording per vraag"} checked={showAttachmentPerQuestion} onChange={() => { setShowAttachmentPerQuestion(!showAttachmentPerQuestion) }}></CheckBox>

                <div ref={ref} style={{ display: 'none' }}>
                    {charts.map(c => { return c })}
                </div>
            </DialogContent>
            <DialogActions>

                <Button onClick={handleDownload} disabled={!ref.current}>Rapport aanmaken & downloaden</Button>
                <Button onClick={onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
