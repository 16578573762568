import React, { useEffect, useState } from 'react'
import Rights from './rights';
import Service from '../../services/evaluations';
import ServiceDepartments from '../../services/department';
import ServiceSurveys from '../../services/survey';
import { TextField, TextArea, ImageUpload, Tabs, CheckBox, Button } from '../ui/core';
import { v4 as uuidv4 } from 'uuid';
import Settings from "../../AppSettings";
const locationAPI = Settings.getLocationAPI();
export default function Item({ history, item, readOnly, onSave }) {
    const [editItem, setEditItem] = useState(item);
    const [selectedTab, setSelectedTab] = useState(0);
    const [department, setDepartment] = useState();
const [surveys,setSurveys] = useState([]);

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, editItem);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setEditItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        tmpItem._id = editItem._id;
        onSave(tmpItem);
    }

    const handleChangeAndSave = (attribute, value) => {
       if( window.confirm("Weet u zeker dat u een (nieuwe) links wil aanmaken? De oude komen hiermee te vervallen.")){
        let tmpItem = Object.assign({}, editItem);
        tmpItem[attribute] = value;
        setEditItem(tmpItem);

        let tmpItemSave = {};
        tmpItemSave._id = editItem._id;
        tmpItemSave[attribute] = value;
        onSave(tmpItemSave);
       }
     
    }

    useEffect(() => {
        setEditItem(item); 
        if (item.department) {
            ServiceDepartments.get(item.department)
                .then(dep => { setDepartment(dep) })
        }
        ServiceSurveys.getForEvaluation(item._id)
        .then(surveys=>{ 
            setSurveys(surveys);
        })

    }, [item])

    const handleDeleteSurvey = () => {
        var check = window.confirm("Weet u zeker dat u deze evaluatie met onderliggende vragenlijsten wilt verwijderen?");
        if (check) {
            Service.remove({ _id: editItem._id })
                .then(result => {
                    history.push('/evaluatie');
                })
                .catch(ex => { console.log(ex) })
        }
    }

    return (
        <>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="ui-tab">ALGEMEEN</div>
                <div className="ui-tab">BETROKKENEN</div>
                <div className="ui-tab">WAARNEMERS</div>
            </Tabs>
            {selectedTab === 0 &&
                <div className="padding">
                    {editItem && <>
                        <TextField readOnly={readOnly} id="title" label="Titel" value={editItem.title} onChange={handleChange} onBlur={handleSave}></TextField>
                        <TextArea readOnly={readOnly} id="description" label="Omschrijving" value={editItem.description} onChange={handleChange} onBlur={handleSave}></TextArea>
                        {department && <> <br /><div className="label">Locatie</div>{department.name}<br /><br /></>}
                        <div className="label">Archiveren</div>
                        <CheckBox label="In archief plaatsen" checked={item.archived || false} onChange={(ev) => { handleChangeAndSave('archived', ev.target.checked) }} />
                        {!readOnly && <ImageUpload label="Afbeelding" value={editItem.image} onChange={(ev) => { handleChangeAndSave('image', ev.target.value); }} resize={350} />}

                        <br /><br /><br /><br />
                        <Button onClick={handleDeleteSurvey} style={{ color: 'red' }} variant="outlined" fullWidth>Verwijder {editItem.title}</Button>

                    </>}
                </div>}
            {selectedTab === 1 && <div className="padding"><Rights evaluation={editItem} onSave={onSave} /></div>}
            {selectedTab === 2 && <div className="padding">

                <TextArea readOnly={readOnly} id="intro" label="Introductietekst voor waarnemer" value={editItem.intro} onChange={handleChange} onBlur={handleSave}></TextArea>
                <br /><br />
               <div className='label'> Algemene link voor de waarnemers voor deze evaluatie:</div>
                {editItem.code && <>
                <a target="_blank" href={`https://apps.scherpinveiligheid.nl/#/vragenlijst/S-${editItem._id}-${editItem.code}-0`}>{`https://apps.scherpinveiligheid.nl/#/vragenlijst/S-${editItem._id}-${editItem.code}-0`}</a><br/>
                <img src={locationAPI+'qrcode?url='+ encodeURIComponent(`https://apps.scherpinveiligheid.nl/#/vragenlijst/S-${editItem._id}-${editItem.code}-0`)}/>
                </>
                }
                <br/><br/>
                {surveys.map(survey=>{
                    return <div key={survey._id}>
                         <div className='label'> Link voor de waarnemers voor de vragenlijst <b>{survey.title}</b>:</div>
                {editItem.code && <>
                <a target="_blank" href={`https://apps.scherpinveiligheid.nl/#/vragenlijst/S-${editItem._id}-${editItem.code}-${survey._id}`}>{`https://apps.scherpinveiligheid.nl/#/vragenlijst/S-${editItem._id}-${editItem.code}-${survey._id}`}</a><br/>
                <img src={locationAPI+'qrcode?url='+ encodeURIComponent(`https://apps.scherpinveiligheid.nl/#/vragenlijst/S-${editItem._id}-${editItem.code}-${survey._id}`)}/>
                </>
                }<br/><br/>
                        </div>
                })}
                <br /><br /><Button onClick={() => {
                    handleChangeAndSave('code', uuidv4().replace(/-/g,''))
                }}>Maak nieuwe link aan</Button>
            </div>}
        </>

    )
}
