import React, { useEffect, useContext, useState } from 'react'

import { Context } from '../../AppContext';
import { SearchBar, Paper, Tabs, Menu, MenuItem, IconButton } from '../../components/ui/core';
import { ContextUI } from '../../uiContext';
import Item from '../../components/organisations/item';
import Service from '../../services/organisation';
import ServiceDepartment from '../../services/department';
import ServiceUser from '../../services/user';
import ListUsers from '../../components/users/list';
import ListDepartments from '../../components/departments/list';
import { Add } from '../../components/ui/coreIcons';

export default function Index(props) {
    const [item, setItem] = useState();
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [selectedTab, setSelectedTab] = useState(0);
    const [readOnly, setReadOnly] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (context.organisation) {
            contextUI.setShowHeader(true);
            contextUI.setTitle('Beheer');
            contextUI.setSubTitle(context.organisation.name);
            contextUI.setShowBackButton(false);
            contextUI.setHeaderActions();
            setItem(context.organisation);
            let hasRight = ServiceUser.canWrite(context.user.rights, 'organisations', context.organisation._id);
            setReadOnly(!hasRight);
            getDepartments();
            getUsers();
        }

    }, [context.organisation])

    const getDepartments = () => {
        ServiceDepartment.getForOrganisation(context.organisation._id)
            .then(result => { setDepartments(result) })
    }

    const getUsers = () => {
        ServiceUser.getForOrganisation(context.organisation._id)
            .then(result => { setUsers(result) })
    }

    useEffect(() => {
        if (selectedTab === 1) {
            contextUI.setHeaderActions(<IconButton onClick={() => { addDepartment() }}><Add color="white" /></IconButton>)
        }
        if (selectedTab === 2) {
            contextUI.setHeaderActions(<IconButton onClick={() => { addUser() }}><Add color="white" /></IconButton>)
        }
    }, [selectedTab]);


    const addDepartment = () => {
        let newItem = {};
        newItem.organisation = context.organisation._id;
        newItem.name = "";
        newItem.active = true;
        ServiceDepartment.insert(newItem)
            .then(newDepartment => {
                props.history.push('/organisatie/locaties/' + newDepartment._id)
            })
            .catch(err => {

            })
    }
    const addUser = () => {
        let updateItem = {}; 
        updateItem.organisations = [];
        updateItem.organisations.push(context.organisation._id);
        ServiceUser.insert(updateItem).then(result => {
            props.history.push('/organisatie/gebruikers/' + result._id)
          
        }).catch(ex => { })
        /*
        let newItem = {};
        newItem.organisation = context.organisation._id;
        newItem.name = "";
        newItem.active = true;
        ServiceUser.insert(newItem)
            .then(newUser => {
                props.history.push('/organisatie/gebruikers/' + newUser._id)
            })
            .catch(err => {

            })
            */
    }

    const handleSave = (item) => {
        let tmpOrganisation = Object.assign({}, context.organisation);
        Object.keys(item).forEach(key => {
            tmpOrganisation[key] = item[key];
        })
        context.setOrganisation(tmpOrganisation);
        Service.update(item).then(result => { }).catch(ex => { console.log(ex) })
    }


    const handleClickUser = (user) => {
        props.history.push('/organisatie/gebruikers/' + user._id)
    }

    const handleClickDepartment = (department) => {
        props.history.push('/organisatie/locaties/' + department._id)
    }

    return (
        <>
            <Paper>
                <Tabs value={selectedTab} onChange={setSelectedTab} >
                    <div className='ui-tab'>ALGEMEEN</div>
                    <div className='ui-tab'>LOCATIES</div>
                    <div className='ui-tab'>MEDEWERKERS</div>
                </Tabs>
            </Paper>
            {selectedTab === 0 && item && <Item item={item} onSave={handleSave} readOnly={readOnly} hideArchive={true} hideNote={true} />}
            {selectedTab === 1 && <>
                <div className="padding"><Paper><ListDepartments items={departments} onClick={handleClickDepartment} /></Paper></div>

            </>}
            {selectedTab === 2 && <>
                <div className="padding"><Paper><ListUsers items={users} onClick={handleClickUser} /></Paper></div>

            </>}
        </>)
}
