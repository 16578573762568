import React, { useState, useEffect, useContext } from 'react'
import ServiceSurvey from '../../../services/survey';
import ServiceQuestion from '../../../services/question';
import ServiceAnswers from '../../../services/answers';
import ServiceOrganisations from '../../../services/organisation';
import ServiceGeneral from '../../../services/general';
import InputFormControl from './inputformcontrol';
import InputParamsControl from './inputparamscontrol';
import { Typography, Paper, Button } from '../../ui/core'
import { ContextUI } from '../../../uiContext';
export default function InputForm({ respondent, optionalSurvey, optionalQuestions, optionalParams, filter, onChange, selectedSubProcess, setSelectedSubProcess, showSaveButton }) {
    const [survey, setSurvey] = useState();
    const [questions, setQuestions] = useState()
    const [answers, setAnswers] = useState();
    const [params, setParams] = useState();
    const [isDirty, setIsDirty] = useState(false);
    const [changed, setChanged] = useState(false)
    const contextUI = useContext(ContextUI);
    //INITIAL VALUES
    //SURVEY
    useEffect(() => {
        if (respondent && !survey) {

            if (optionalSurvey) {
                setSurvey(optionalSurvey);
            } else {
                ServiceSurvey.get(respondent.survey)
                    .then(result => {
                        setSurvey(result);
                    });
            }
        }
    }, [respondent])

    useEffect(() => {
        if (selectedSubProcess && selectedSubProcess.length > 0) {
            var elmnt = document.getElementsByClassName("subprocess_" + selectedSubProcess);

            if (elmnt && elmnt[0]) {
                elmnt[0].scrollIntoView({ behavior: 'smooth' });
                setSelectedSubProcess && setSelectedSubProcess('');
            }
        }
    }, [selectedSubProcess])

    //QUESTIONS
    useEffect(() => {
        if (respondent && !questions) {
            if (optionalQuestions) {
                setQuestions(optionalQuestions.sort((a, b) => { return a.sort - b.sort }));
            } else {
                ServiceQuestion.getForSurvey(respondent.survey)
                    .then(result => {
                        result = result.sort((a, b) => { return a.sort - b.sort })
                        setQuestions(result);
                    });
            }
        }
    }, [respondent])

    //ORGANISATION
    useEffect(() => {
        if (respondent && survey && !params) {
            if (optionalParams) {
                setParams(optionalParams);
            } else {
                ServiceOrganisations.get(survey.organisation)
                    .then(organisation => {
                        let tmpParams = [];
                        if (survey.params && organisation.params)
                            for (let i = 0; i < organisation.params.length; i++) {
                                if (survey.params.indexOf(organisation.params[i]._id) >= 0) {
                                    tmpParams.push(organisation.params[i])
                                }
                            }
                        setParams(tmpParams)

                    })
                    .catch(ex => { setParams([]) })
            }
        }
    }, [respondent, survey])

    //ANSWERS
    useEffect(() => {
        if (respondent && questions && !answers) {
            ServiceAnswers.getForSurveyRespondent(respondent.survey, respondent._id)
                .then(result => {
               
                    // setAnswers(result);
                    checkOfflineAnswers(result)
                })
                .catch(ex => {
                    //try getting from localstorage
                    // setAnswers([])
                    checkOfflineAnswers([])
                })
            cleanAnswersFromStorage();
        }
    }, [respondent, questions])

    const checkOfflineAnswers = (answers) => {
        questions.forEach(question => {
        
            let foundAns = getAnswerFromLocalStorage(question, null)
         
            if (foundAns) {
            
                let found = answers.find(ans => { return ans.question === question._id && !ans.option });
    
                if (!found) { 
                    foundAns.saved = false;
                    answers.push(foundAns);
                }else{
                    if(foundAns.lastUpdate> found.lastUpdate){
                        found.active = false;
                        answers.push(foundAns);
                    }
                }
            }

            if (question.options) {
                question.options.forEach(option => {
                    let foundAns = getAnswerFromLocalStorage(question, option);
                    if (foundAns) {
                        let found = answers.find(ans => { return ans.question === question._id && option._id === ans.option }); 
                        if (!found) { 
                            foundAns.saved = false;
                            answers.push(foundAns);
                        }
                    }
                })
            }
        })
        answers = fixCheckBoxOnlyOneItem(questions, answers);
        setAnswers(answers)
    }

    const fixCheckBoxOnlyOneItem = (questions, answers) => {
        questions.forEach(question => {
                let foundAnswers = answers.filter(ans => { return ans.question === question._id && ans.active===true });
               
                if(question.type===3 && foundAnswers.length>0){
                    let latest; 
                    foundAnswers.forEach(ans=>{
                        if(ans.option){
                            if(!latest){
                                latest = ans;
                            }else{
                                if(latest.lastUpdate< ans.lastUpdate){                              
                                    latest.active = false;
                                    latest.checked = false;
                                    latest = ans;
                                }else{
                                    ans.active = false;
                                    ans.checked = false;
                                }
                            }
                        }
                    
                    })
                    
                }
        })
        return answers;
    }


    const getValue = (question, option) => {
        let found
        if (option) {
            found = answers.find(answer => { return answer.question === question._id && answer.option === option._id });

            return found ? found.checked : false;
        } else {
            found = answers.find(answer => { return answer.question === question._id });

            return found ? found.value : null;
        }
    }

    const getSavedAnswers = (question, option) => {
        let found
        if (option) {
            found = answers.find(answer => { return answer.question === question._id && answer.option === option._id });

            return found;
        } else {
            found = answers.find(answer => { return answer.question === question._id });

            return found;
        }
    }

    const getValueAttribute = (question, attribute) => {
        let found = answers.find(answer => { return answer.question === question._id && !answer.option && answer.active===true});

        return found ? found[attribute] : null;
    }

    const markForSaving = (question) => {
        let tmpAnswers = Object.assign([], answers);
        tmpAnswers.forEach(answer => {
            if (question._id === answer.question) {
                answer.saved = false;
            }
        });
        setAnswers(tmpAnswers)
    }

    const saveAnswerToLocalStorage = (answer) => {
        if (answer) {
            localStorage.setItem("lss_" + answer.respondent + "_" + answer.question + "_" + answer.option, ServiceGeneral.encryptWithAES(JSON.stringify(answer), respondent.code));
        }
    }

    const getAnswerFromLocalStorage = (question, option) => {
        if (respondent && question) {
            try {
                if (localStorage.getItem("lss_" + respondent._id + "_" + question._id + "_" + (option ? option._id : "null"))) {
                    const found = JSON.parse(ServiceGeneral.decryptWithAES(localStorage.getItem("lss_" + respondent._id + "_" + question._id + "_" + (option ? option._id : "null")), respondent.code));
                
                    return found;
                } else {
                    return null;
                }

            } catch (ex) {
                console.log(ex)
            } return null
        }
        return null;
    }
    /*
    const getAnswerFromLocalStorage = (respondent) => {
        let items = [];
        if (respondent) {
            for (var key in localStorage) {
                if (key.indexOf("lss_" + respondent) === 0) {
                    try {
                        let item = JSON.parse(localStorage.getItem(key));
                        items.push(item);
                    } catch (ex) { console.log(ex) }
                }
            }
        }
        return items; 
    }
*/
    const cleanAnswersFromStorage = (answer) => {
        if (answer) {
            localStorage.removeItem("lss_" + answer.respondent + "_" + answer.question + "_" + answer.option);
        } else {
            for (var key in localStorage) {
                if (key.indexOf('lss_') == 0) {
                    try {
                        let item = JSON.parse(ServiceGeneral.decryptWithAES(localStorage.getItem(key), respondent.code));
                        //delete older then 30 days
                        if ((new Date(item.lastUpdate).getTime() + (30 * 24 * 60 * 60 * 1000) < new Date().getTime()) === true) {
                            localStorage.removeItem(key);
                        }
                    } catch (ex) { }
                }
            }
        }
    }
    const cleanAnswersFromStorageForRespondent = (respondent) => {
       
            for (var key in localStorage) {
                if (key.indexOf('lss_' + respondent._id) == 0) {
                    localStorage.removeItem(key);
                }
            }
        
    }
    useEffect(() => {

        setChanged(true);
        let tmpAnswers = Object.assign([], answers);
        let found = tmpAnswers.filter(ans => {
            saveAnswerToLocalStorage(ans);
            return !ans.saved
        });
        if (found.length > 0) {
            let promiseArr = [];
            found.forEach((answer) => {
                promiseArr.push(ServiceAnswers.update(answer));
                // let updateAction = await ServiceAnswers.update(answer);
                // console.log(updateAction);
                /*
                     .then(result => {
                         //    answer.saved = true;
                         onChange && onChange();
                     })
                     .catch(ex => {
                         contextUI.setMessage('Het antwoord is NIET opgeslagen. Controleer uw internetverbinding of probeer het later nogmaals.')
                         console.log(ex);
                         setIsDirty(true);
                     })
                     */
            });
            Promise.all(promiseArr)
                .then(results => {
                    onChange && onChange();
                    tmpAnswers.map(answer => {
                        answer.saved = true;
                        cleanAnswersFromStorage(answer);
                        return answer
                    });
                    setAnswers(tmpAnswers);
                }, err => {
                    contextUI.setMessage('Het antwoord is NIET opgeslagen. Controleer uw internetverbinding of probeer het later nogmaals.')
                    console.log(err);
                    setIsDirty(true);
                })

        }
    }, [answers])

    const processSurveyDefaults = (survey, question, answer, respondent) => {
        if (question.subProcess) {
            answer.subProcess = question.subProcess;
            let foundMainProcess = survey.mainProcesses.find(proc => { if (!proc.subProcesses) return false; if (proc.subProcesses.find(sub => { return sub._id === question.subProcess })) { return true } else { return false; } });
            if (foundMainProcess) {
                answer.mainProcess = foundMainProcess._id
                answer.mainProcessCode = foundMainProcess.code;
                let foundSub = foundMainProcess.subProcesses.find(sub => { return (sub._id === question.subProcess) })
                if (foundSub) answer.subProcessCode = foundSub.code;
            }
        }
        answer.paramValues = respondent.paramValues;
        answer.organisation = survey.organisation;
        return answer;
    }

    const handleChange = (question, value, remark) => {
        let tmpAnswers = Object.assign([], answers);
        let found = tmpAnswers.find(ans => { return ans.question === question._id });
        if (found) {
            found = processSurveyDefaults(survey, question, found, respondent)
            if (value !== undefined) found.value = value;
            if (remark !== undefined) found.remark = remark;
            found.lastUpdate = new Date();
        } else {
            let tmpAnswer = {};
            tmpAnswer = processSurveyDefaults(survey, question, tmpAnswer, respondent)
            tmpAnswer.survey = survey._id;
            tmpAnswer.respondent = respondent._id;
            tmpAnswer.question = question._id;
            tmpAnswer.active=true;
            if (value !== undefined) tmpAnswer.value = value;
            if (remark !== undefined) tmpAnswer.remark = remark;
            tmpAnswer.lastUpdate = new Date();
            tmpAnswers.push(tmpAnswer)
        }
        setAnswers(tmpAnswers);
    }

    const handleChangeAttribute = (question, attribute, value, markForSaving) => {
        let tmpAnswers = Object.assign([], answers);
        let found = tmpAnswers.find(ans => { return ans.question === question._id && !ans.option && ans.active===true});
        if (found) {
            found = processSurveyDefaults(survey, question, found, respondent);
            found[attribute] = value;
            if (markForSaving) {
                found.saved = false;
            }
            found.lastUpdate = new Date();
        } else {
            let tmpAnswer = {};
            tmpAnswer = processSurveyDefaults(survey, question, tmpAnswer, respondent);
            tmpAnswer[attribute] = value;
            tmpAnswer.survey = survey._id;
            tmpAnswer.respondent = respondent._id;
            tmpAnswer.question = question._id;
            tmpAnswer.active=true;
            if (markForSaving) {
                tmpAnswer.saved = false;
            }
            tmpAnswer.lastUpdate = new Date();
            tmpAnswers.push(tmpAnswer);
        }
        setAnswers(tmpAnswers);
    }

    const handleChangeCheck = (question, option, checked) => {
        let tmpAnswers = Object.assign([], answers);
        if (question.type === 3) {
            for (let i = 0; i < tmpAnswers.length; i++) {
                if (tmpAnswers[i].question === question._id && tmpAnswers[i].option) {
                    tmpAnswers[i].checked = false;
                    tmpAnswers[i].active = false;
                    tmpAnswers[i].saved = false;
                }
            }
        }

        let found = tmpAnswers.find(ans => { return ans.question === question._id && ans.option === option._id });
        if (found) {
            found = processSurveyDefaults(survey, question, found, respondent)
            found.checked = checked;
            found.score = option.score;
            found.maxValue = question.options.reduce((a, b) => { return a > b.score ? a : b.score })
            found.active = checked;//true
            found.saved = false;
            found.lastUpdate = new Date();
        } else {
            let tmpAnswer = {};
            tmpAnswer = processSurveyDefaults(survey, question, tmpAnswer, respondent)
            tmpAnswer.survey = survey._id;
            tmpAnswer.maxValue = question.options.reduce((a, b) => { return a > b.score ? a : b.score })
            tmpAnswer.respondent = respondent._id;
            tmpAnswer.question = question._id;
            tmpAnswer.option = option._id;
            tmpAnswer.score = option.score;
            tmpAnswer.checked = checked;
            tmpAnswer.saved = false;
            tmpAnswer.active = checked;//true
            tmpAnswer.lastUpdate = new Date();
            tmpAnswers.push(tmpAnswer);
        }
        setAnswers(tmpAnswers);
    }

    const handleSave = () => {
        let tmpAnswers = Object.assign([], answers);
        let arrSavedItems = [];
        tmpAnswers.forEach(answer => {
            arrSavedItems.push(ServiceAnswers.update(answer));
        });
        Promise.all(arrSavedItems)
            .then(results => {
                cleanAnswersFromStorageForRespondent(respondent);
                setAnswers(tmpAnswers);
                contextUI.setMessage('Alle wijzigingen zijn opgeslagen, u kunt de vragenlijst afsluiten');
                setIsDirty(false);
                setChanged(false);
            }, err => {
                contextUI.setMessage('Het antwoord is NIET opgeslagen. Controleer uw internetverbinding of probeer het later nogmaals.')
                console.log(err);
                setIsDirty(true);
            })
        /*
         answers.forEach(answer => {
             ServiceAnswers.update(answer)
                 .then(result => {
                     contextUI.setMessage('Alle wijzigingen zijn opgeslagen, u kunt de vragenlijst afsluiten');
                     setIsDirty(false);
                     setChanged(false);
                 })
                 .catch(ex => {
                     contextUI.setMessage('Het antwoord is NIET opgeslagen. Controleer uw internetverbinding of probeer het later nogmaals.')
                     console.log(ex);
                     setIsDirty(true);
                 })
         })
         */
    }


    if (!survey || !questions) {
        return null
    }

    return (
        <>
            {survey && questions && params && answers && <>
                {filter && filter.length > 0 && <><Paper className="padding">Onderstaande vragen zijn gefiltered op <b>"{filter}"</b></Paper><br /></>}
                {params.length > 0 && <Paper className="padding">
                    <Typography component="h3">Parameters</Typography>
                    <InputParamsControl respondent={respondent} params={params} />
                </Paper>}<br />

                {questions && questions.map(question => {
                    let show = false;
                    if (filter && filter.length > 0) {
                        if (question.question.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
                            show = true
                        }
                    } else {
                        show = true;
                    }
                    if (show) {
                        return <Paper className={"padding subprocess_" + question.subProcess} style={{ marginBottom: '6px' }} key={question._id}>
                            <InputFormControl respondent={respondent} question={question} getValue={getValue} getSavedAnswers={getSavedAnswers} getValueAttribute={getValueAttribute} onChange={handleChange} onChangeAttribute={handleChangeAttribute} onChangeCheck={handleChangeCheck} markForSaving={markForSaving} />
                        </Paper>
                    } else {
                        return null
                    }
                })}
                {(showSaveButton || isDirty) && <Button onClick={handleSave} fullWidth={true} disabled={!changed}>{changed ? 'Opslaan' : '- alle wijzigingen zijn opgeslagen, u kunt de vragenlijst afsluiten-'}</Button>}
            </>}
        </>
    )
}
