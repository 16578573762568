import { Plugins } from "./plugins/capacitor";

if (!window.__context) {

    window.__context = {
        cache: [],
        locationAPI: '/api/v1/',
        locationWordDocumentAPI: '/worddocumentapi/v1/',
        locationOpendocumentAPI: '/opendocumentapi/v1/',
        locationDocuments: '/documents/',
        isApp: false,
        platform: 'web',
        JWT: '',
        uuid: '',
        device: {},
        database: [],
        menu: [],
    }

    //TEST ENVIROMENT
    if ((document.location.href.indexOf('localhost:3001') >= 0)||(document.location.href.indexOf('localhost:3000') >= 0)) {
        window.__context.locationAPI = 'http://localhost:4000/api/v1/';
        window.__context.locationWordDocumentAPI = 'http://localhost:4001/worddocumentapi/v1/';
        window.__context.locationOpendocumentAPI = 'http://localhost:4002/opendocumentapi/v1/';
        //      window.__context.locationAPI = 'https://apps.scherpinveiligheid.nl/api/v1/' ;
        //     window.__context.locationWordDocumentAPI = 'https://apps.scherpinveiligheid.nl/worddocumentapi/v1/' ;
        //     window.__context.locationOpendocumentAPI = 'https://apps.scherpinveiligheid.nl/opendocumentapi/v1/' ;
    }
}

const settings = {
    getPlatform() {
        return window.__context.platform;
    },
    getUUID() {
        return window.__context.uuid;
    },
    getDevice() {
        return window.__context.device;
    },
    getContext() {
        return window.__context;
    },
    getLocationAPI() {
        return window.__context.locationAPI
    },
    getLocationWordDocumentAPI() {
        return window.__context.locationWordDocumentAPI
    },
    getLocationOpenDocumentAPI() {
        return window.__context.locationOpendocumentAPI
    },
    getLocationDocumentAPI() {
        return window.__context.locationDocuments;
    },
    getJWT() {
        if (window.localStorage.getItem('t')) {
            window.__context.JWT = window.localStorage.getItem('t');
        }
        return window.__context.JWT;
    },
    setJWT(jwt) {
        window.localStorage.setItem('t', jwt);
        window.__context.JWT = jwt;
    },
    getCollection(collection) {
        return window.__context.database[collection];
    },
    setCollection(collection, value) {
        window.__context.database[collection] = value;
    },
    setCache(item, value) {
        window.__context.cache[item] = value;
    },
    getCache(item) {
        return window.__context.cache[item];
    }
}

//Electron

window.sendMessage = (subject, message) => {
    if (window.ipc) {
        window.ipc.send(subject, message);
    }
}

if (Plugins) {
    const { Device } = Plugins;
    Device.getInfo().then(
        (result) => {
            window.__context.platform = result.platform;
            window.__context.isApp = (result.platform !== 'web');
            window.__context.uuid = result.uuid || '';
            result.versionapp = result.appVersion;
            result.version = result.osVersion;
            window.__context.device = result;
            if (result.platform === 'electron') {
                window.ipc = window.require('electron').ipcRenderer;
                window.ipc.on('downloadcomplete', (evt, arg) => {

                })
            }
        },
        (err) => { }
    )
}

export default settings;