import React, { useState, useContext, useEffect } from 'react'
import MonitorEdit from './monitor-edit';
import MonitorDash from './monitor-dash';
import ServiceEvaluationMonitor from '../../services/evaluationmonitor';
import ServiceSurveys from '../../services/survey';
import ServiceEvaluation from '../../services/evaluations';
import GeneralService from '../../services/general';
import { Context } from '../../AppContext';

export default function Monitor({ history }) {
    const context = useContext(Context);
    const [currentEvaluationMonitor, setCurrentEvaluationMonitor] = useState();
    const [monitors, setMonitors] = useState([]);
    const [processes, setProcesses] = useState([]);
    const [evaluations, setEvaluations] = useState([]);

    useEffect(() => {
        if (context.organisation) {
            getMonitors();
            getEvaluations();
            ServiceSurveys.getProcessesForOrganisation(context.organisation._id)
                .then(result => {
                    setProcesses(result)
                })
        }

    }, [context.organisation])

    const getMonitors = () => {
        ServiceEvaluationMonitor.getForOrganisation(context.organisation._id)
            .then(result => {
                setMonitors(result);
            })
            .catch(ex => { })
        setCurrentEvaluationMonitor();
    }

    const getEvaluations = () => {
        ServiceEvaluation.getForOrganisation(context.organisation._id)
            .then(result => setEvaluations(GeneralService.sortJSON(result, 'created', '321')))
            .catch(err => { console.log(err) })
    }

    const handleDelete = (monitor) => {
        if (window.confirm("Weet u zeker dat u deze monitor wilt verwijderen?")) {
            ServiceEvaluationMonitor.remove(monitor)
                .then(result => {
                    getMonitors();
                })
                .catch(ex => {
                    getMonitors();
                })
        }
    }

    const handleSave = (monitor) => {
        if (!monitor._id) {
            ServiceEvaluationMonitor.insert(monitor)
                .then(result => {
                    getMonitors();
                })
                .catch(ex => {
                    getMonitors();
                })
        } else {
            ServiceEvaluationMonitor.update(monitor)
                .then(result => {
                    getMonitors();
                })
                .catch(ex => {
                    getMonitors();
                })
        }
    }

    return (
        <div className="padding">
            {currentEvaluationMonitor && <MonitorEdit evaluationMonitor={currentEvaluationMonitor} onClose={() => { setCurrentEvaluationMonitor() }} onSave={handleSave} processes={processes} evaluations={evaluations} />}

            {monitors.map(monitor => {
                return <MonitorDash history={history} key={monitor._id} monitor={monitor} onEdit={() => setCurrentEvaluationMonitor(monitor)} onDelete={() => handleDelete(monitor)} />

            })}

            <div onClick={() => { setCurrentEvaluationMonitor({ title: 'Monitor ...', organisation: context.organisation._id, user: context.user._id }) }} className="card" style={{ border: '2px dashed silver' }}>
                <div className="card-content" style={{ textAlign: 'center', fontSize: '70px', color: 'gray' }}>
                    <br />+
                </div>
            </div>
        </div>
    )
}
