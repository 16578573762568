import React,{useEffect,useState} from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '../../ui/core'
import ServiceQuestions from '../../../services/question';
import GeneralService from '../../../services/general';
export default function Info({survey,onClose}) {
const [questions,setQuestions] = useState([])
    useEffect(() => {
        if(survey){
            ServiceQuestions.getForSurvey(survey._id)
            .then(result=>{
                setQuestions(GeneralService.sortJSON(result,'code','123'))})
            return () => {  }
        }   
    }, [survey])
    return (
        <Dialog fullScreen={true}>
            <DialogTitle ><Typography component="h1">Gebruik rapportage</Typography></DialogTitle>
            <DialogContent>
                Gebruik in het rapport de volgende "tags" voor het automatisch invullen van de juiste waarden.
                Zorg ervoor dat bij de vragen de juiste code is ingevoerd.<br/><br/>
                <b>Tags per vraag</b><br/>
                <table><tbody><tr><td>Vraag</td><td>Score/antwoord</td><td>Score (%)</td><td>Waarneming</td><td>Verwoording score</td></tr>
                {questions.map(question=>{
                    if(question.code === undefined) return null;
                    return <tr key={question._id}><td>{'{vraag_'+question.code+'}'}</td><td>{(question.type>=1 && question.type <=4) && '{vraag_score_'+question.code+'}'}</td><td>{(question.type==3||question.type==4) && '{vraag_score_procent_'+question.code+'}'}</td><td>{question.hasOpenText===true && '{vraag_waarneming_'+question.code+'}'}</td><td>{(question.type==3||question.type==4) && '{vraag_score_verwoording_'+question.code+'}'}</td></tr>
                })}
                </tbody></table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Sluiten</Button>
            </DialogActions>
        </Dialog>
    )
}
