import React, { useState, useEffect } from 'react'
import { Paper } from '../ui/core'
import Service from '../../services/survey';
import ServiceGeneral from '../../services/general';

import RadarChart from '../charting/radar/index.js';
import Grid from '../charting/radar/grid.js';
import Radar from '../charting/radar/radar.js';
import Label from '../charting/radar/labels.js';
export default function Dashboard({ evaluation, surveys }) {
    const [allScores, setAllScores] = useState({});
    const [scores, setScores] = useState([])
    useEffect(() => {
        Service.getScoresEvaluation(evaluation._id)
            .then(scores => {
                setAllScores(scores);
                // calCulateScores(survey, scores)
            })
            .catch(err => { console.log(err) })
        return () => {

        }
    }, [])

    const calculateProgress = () => {
        let sumProgress = 0;
        for (let i = 0; i < surveys.length; i++) {
            sumProgress += (surveys[i].progress || 0);
        }
        let result = Math.round(sumProgress / surveys.length);
        if (isNaN(result)) {
            return '-';
        } else {
            return '' + result;
        }

    }

    useEffect(() => {
        if (allScores && allScores.mainProcessScore && surveys.length > 0) {
            let tmpScores = allScores.mainProcessScore.map(score => {

                let n = {};
                n._id = score._id;
                n.score = score.perc;
                n.title = getMainProcessName(score._id);
                n.color = Service.getColorFromRange(surveys[0].colorRange, score.perc);
                n.max = 100;
                return n;

            });
            tmpScores = tmpScores.filter(score => { return score._id })
            setScores(tmpScores)
        }

    }, [allScores, surveys])


    const boxStyle = { width: '250px', boxSizing: 'border-box', float: 'left', minHeight: '250px', margin: '20px', backgroundColor: 'rgb(0, 105, 143)', color: 'white' }

    const getMainProcessName = (code) => {
        if (surveys) {
            for (let i = 0; i < surveys.length; i++) {
                if (surveys[i].mainProcesses) {
                    let found = surveys[i].mainProcesses.find(pro => { return pro.code === code && pro.active === true });
                    if (found) {
                        return found.title;
                    }
                }
            }
        }
        return "";
    }


    const getAvgTotalScore = (scores) => {
        return scores.reduce((a, b) => { return { score: (a.score + b.score) } }).score / scores.length;
    }


    return (
        <div className="padding">
            <Paper className="padding" style={boxStyle}>
                <h1 style={{ color: 'white' }}>Statistiek</h1>
                <span style={{ fontSize: '30px' }}>{surveys.length}</span> vragenlijsten<br />
                Percentage ingevoerd <span style={{ fontSize: '30px' }}>{calculateProgress()}%</span>
            </Paper>
            <Paper className="padding" style={{ ...boxStyle, width: '500px' }}>
                <h1 style={{ color: 'white' }}>Planning</h1>
                <table cellSpacing={'8px'}><tbody>
                    {surveys.map(survey => {
                        if (!survey.date) { return null } else {
                            return <tr key={survey._id}><td>{survey.title}</td><td>{ServiceGeneral.formatDate(survey.date, true)}</td></tr>
                        }
                    })}
                </tbody></table>
            </Paper>
            {allScores.mainProcessScore && allScores.mainProcessScore.length > 0 && surveys.length > 0 && scores.length > 0 && <>
                <Paper className="padding" style={{ ...boxStyle, width: '500px', backgroundColor: 'white' }}>
                    <h1 >Beoordeling hoofdprocessen</h1>

                    <RadarChart width={400} height={400} data={scores} settings={{ maxValueX: 100, title: '' }}>
                        <Grid gridDistanceX={10} />
                        <Radar dataKey={'score'} stroke="none" fill={Service.getColorFromRange(surveys[0].colorRange, getAvgTotalScore(scores))} fillOpacity={1} />
                        <Label dataKey={'title'} onClick={() => { }} />
                    </RadarChart>
                </Paper>
            </>}

        </div>
    )
}
