import React, { useEffect, useState } from 'react'
import { TextField, TextArea, IconButton, Menu, MenuItem, Divider, CheckBox, Select } from '../../ui/core';
import { Delete, MoreVert } from '../../ui/coreIcons';
import Service from '../../../services/question';
import { v4 as uuidv4 } from 'uuid';

export default function Question({ question, onSave, onDelete, mainProcesses, subProcesses, hideCode }) {
    const [item, setItem] = useState();

    useEffect(() => {
        let tmpItem = Object.assign({}, question);
        if (!tmpItem.options || tmpItem.options.length === 0) { tmpItem.options = [] }
        setItem(tmpItem);
    }, [question])

    const handleChange = (ev) => {
        let tmpItem = Object.assign({}, item);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        if (ev.target.id === 'sort') {
            tmpItem[ev.target.id] = parseInt(tmpItem[ev.target.id]);
        }
        setItem(tmpItem);
    }

    const handleSave = (ev) => {
        let tmpItem = {};
        tmpItem[ev.target.id] = ev.target.value;
        if (ev.target.id === 'sort') {
            tmpItem[ev.target.id] = parseInt(tmpItem[ev.target.id]);
        }
        tmpItem._id = item._id;
        onSave(tmpItem);
    }

    const handleSaveOptions = (options) => {
        let tmpItem = {};
        tmpItem.options = options || item.options;
        tmpItem._id = item._id;
        onSave(tmpItem);
    }

    const handleChangeOption = (id, attribute, value) => {
        let tmpItem = Object.assign({}, item);
        for (let i = 0; i < tmpItem.options.length; i++) {
            if (tmpItem.options[i]._id === id) {
                if (attribute === 'score') {
                    tmpItem.options[i][attribute] = parseInt(value);
                } else {
                    tmpItem.options[i][attribute] = value;
                }
            }
        }

        setItem(tmpItem);
    }

    const handleChangeAndSave = (ev) => { 
        let tmpItem = Object.assign({}, item);
        tmpItem[ev.target.id] = ev.target.value || ev.target.checked;
        setItem(tmpItem);
        onSave(tmpItem);
    }


    const deleteOption = (id) => {
        let tmpItem = Object.assign({}, item);
        for (let i = 0; i < tmpItem.options.length; i++) {
            if (tmpItem.options[i]._id === id) {
                tmpItem.options[i].active = false;
            }
        }
        setItem(tmpItem);
        handleSaveOptions(tmpItem.options);
    }

    const newOption = (type) => {
        let tmpItem = Object.assign({}, item);
        if (!tmpItem.options.push) { tmpItem.options = [] }
        switch (type) {
            case 1:
                tmpItem.options.push({ _id: uuidv4(), score: 1, text: "Nee, onvoldoende", active: true });
                tmpItem.options.push({ _id: uuidv4(), score: 2, text: "Nee, voor verbetering vatbaar", active: true });
                tmpItem.options.push({ _id: uuidv4(), score: 3, text: "Ja, basis op orde", active: true });
                tmpItem.options.push({ _id: uuidv4(), score: 4, text: "Ja, op niveau", active: true });
                tmpItem.options.push({ _id: uuidv4(), score: -1, text: "Niet van toepassing", active: true });

                break;
            default:
                tmpItem.options.push({ _id: uuidv4(), score: 0, active: true });

                break;
        }
        setItem(tmpItem);
        handleSaveOptions(tmpItem.options);
    }

    return (<>
        {item && <>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'nowrap', width: '100%' }}>
                <div style={{ width: '80%' }}>
                    {/*TEKSTBLOCK*/ item.type === 0 && <>
                        <TextArea id="text" value={item.text || ''} label={'Tekst'} onChange={handleChange} onBlur={handleSave} />
                    </>}
                    {/*SINGLELINE INPUT*/ item.type === 1 && <>
                        <TextField id="question" value={item.question || ''} label={'Vraag'} onChange={handleChange} onBlur={handleSave} />
                    </>}
                    {/*MULTILINE INPUT*/ item.type === 2 && <>
                        <TextField id="question" value={item.question || ''} label={'Vraag'} onChange={handleChange} onBlur={handleSave} />
                    </>}
                    {/*OPTIONS, ONE INPUT*/ item.type === 3 && <>
                        <TextField id="question" value={item.question || ''} label={'Vraag'} onChange={handleChange} onBlur={handleSave} />
                    </>}
                    {/*OPTIONS, MULTIPLE INPUT*/ item.type === 4 && <>
                        <TextField id="question" value={item.question || ''} label={'Vraag'} onChange={handleChange} onBlur={handleSave} />
                    </>}
                </div>
                <div style={{ width: '10%' }}><TextField id="sort" type="number" label="Volgorde" value={item.sort || 0} onChange={handleChange} onBlur={handleSave}></TextField></div>


                <div style={{ width: '10%' }}>
                    <Menu id={'item_edit_' + item._id} control={<IconButton style={{ float: 'right' }}><MoreVert /></IconButton>} >
                        {(item.type == 3 || item.type == 4) && <MenuItem onClick={() => { newOption() }}>Losse optie</MenuItem>}
                        {(item.type == 3 || item.type == 4) && <MenuItem onClick={() => { newOption(1) }}>Puntenschaal evaluatie app</MenuItem>}

                        <Divider />
                        <MenuItem onClick={() => { onDelete(item) }}>Verwijderen</MenuItem>
                    </Menu>
                </div>
            </div>
          


            {/*OPTIONS */
                (item.type == 3 || item.type == 4) && <>
                    <table style={{ width: '100%', marginTop: '8px' }}><tbody><tr><td className="label">Score</td><td className="label">Optie</td><td className="label">Feedback bij keuze</td><td></td></tr>
                        {item.options.filter && item.options.filter(item => { return item.active === true }).map((option) => {
                            return <tr key={"option_" + option._id} >
                                <td style={{ width: '70px', verticalAlign: 'top' }}><TextField type="number" id={"option_score_" + option._id} value={option.score || '0'} onChange={(ev) => { handleChangeOption(option._id, 'score', ev.target.value) }} onBlur={() => handleSaveOptions()} /></td>
                                <td style={{ verticalAlign: 'top' }}><TextArea id={"option__text_" + option._id} value={option.text || ''} onChange={(ev) => { handleChangeOption(option._id, 'text', ev.target.value) }} onBlur={() => handleSaveOptions()} /></td>
                                <td style={{ verticalAlign: 'top' }}><TextArea id={"option__feedback_" + option._id} value={option.feedback || ''} onChange={(ev) => { handleChangeOption(option._id, 'feedback', ev.target.value) }} onBlur={() => handleSaveOptions()} /></td>
                                <td style={{ width: '70px', verticalAlign: 'top' }}><IconButton onClick={() => { deleteOption(option._id) }}><Delete /></IconButton></td>
                            </tr>
                        })}
                        <tr><td className="label"></td><td className="label">Geef een score van laag (negatief) naar hoog (positief). Gebruik de score -1 wanneer de optie niet in de score berekening moet worden meegenomen, nvt.</td><td className="label"></td><td></td></tr>
                    </tbody></table>
                    {item.options.filter(item => { return item.active === true }).find(item => { return parseInt(item.score)  === 0 }) !== undefined && <div style={{color:'red'}}>Een score met de waarde 0 kan niet worden gebruikt bij het berekenen van een gemiddelde.</div>}
                    {item.options.filter(item => { return item.active === true }).find(item => { return parseInt(item.score)  <-1 }) !== undefined && <div style={{color:'red'}}>Een negatieve score kan niet worden gebruikt bij het berekenen van een gemiddelde.</div>}
                </>
            }
        
              <div style={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'nowrap', width: '100%' }}>


{mainProcesses && mainProcesses.filter(p => { return p.active === true }).length > 0 && <div style={{ width: '40%' }}><Select id="subProcess" label="Proces" value={item.subProcess || ''} onChange={handleChange} onBlur={handleSave}>
    <option value=''></option>
    {mainProcesses.filter(p => { return p.active === true }).map(process => {
        let tmpProcesses = [];
        if(process.subProcesses){
            for (let i = 0; i < process.subProcesses.length; i++) {
                process.subProcesses[i].active===true && tmpProcesses.push(<option value={process.subProcesses[i]._id} key={process.subProcesses[i]._id}>{process.title + ' - '+ process.subProcesses[i].title}</option>)
            }
        }                      
        return tmpProcesses;
    })}
</Select>
</div>}

{item.type !== 0 && hideCode !== true && <div><TextField id="code" label="Code" value={item.code || ''} onChange={handleChange} onBlur={handleSave}></TextField></div>}
<div style={{ width: '10%' }}>&nbsp;</div>
</div>
            <br/>
            <CheckBox id="hasOpenText" checked={item.hasOpenText} onChange={handleChangeAndSave} label={'Toon open tekstveld voor invoeren waarneming'}></CheckBox>
            <CheckBox id="hasImageUpload" checked={item.hasImageUpload} onChange={handleChangeAndSave}  label={'Mogelijkheid tot uploaden document'}></CheckBox>
            <div className="label" style={{ paddingTop: '6px', textAlign: 'right' }}>{Service.getTextLineType(item.type)}</div>
        </>}
    </>
    )
}
