import React, {  useContext, useEffect } from 'react'

import Settings from '../../../components/settings/index';

import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';

export default function SettingsPage(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
 
    //INITIAL SETUP
    useEffect(() => {
        contextUI.setSubTitle('Instellingen');
        contextUI.setShowBackButton(false); 
        contextUI.setHeaderActions();
        return () => { }
    }, [])
    
   if(!context.hasRights(context.user, 'fullcontrol-settings', 'w')) return null;

    return (
        <div>
            <Settings  />
        </div>
    )
}
