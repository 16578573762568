import React, { useState, useContext, useEffect } from 'react'
import Service from '../../services/evaluations';
import ServiceSurvey from '../../services/survey';
import ServiceDepartments from '../../services/department';
import Item from '../../components/module-evaluation/item';
import SurveyView from '../../components/module-survey/surveyview.js'
import QuickSearchSurvey from '../../components/module-survey/quicksearch';
import { CirculairProgress, Menu, Paper, Typography, IconButton, MenuItem, ExpansionPanel, Divider, List, ListItem, ListItemText, ListItemIcon, Button } from '../../components/ui/core';
import { CheckCircle, MoreVert, Settings, ViewList, Grading,Help } from '../../components/ui/coreIcons'
import { ContextUI } from '../../uiContext';
import { Context } from '../../AppContext'; 
import Container from '../../components/sidemenu/container';
import Content from '../../components/sidemenu/content';
import SideMenu from '../../components/sidemenu/menu';
import DashView from '../../components/module-evaluation/dashboard';

export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [item, setItem] = useState();
    const [hideMenu, setHideMenu] = useState(false);
    const [surveys, setSurveys] = useState([]);
    const [showQuickSearchSurvey, setShowQuickSearchSurvey] = useState(false);
    const [templateSurveys, setTemplateSurveys] = useState([]);
    const [currentSurvey, setCurrentSurvey] = useState();
    const [currentView, setCurrentView] = useState('dashboard');
    const [canWrite, setCanWrite] = useState(false);

    const styleMenu = { padding: '12px', cursor: 'pointer', display: 'flex', color: '#333333' }

 

    const handleDelete = () => {
        var check = window.confirm("Weet u zeker dat u deze evaluatie wilt verwijderen?");
        if (check) {
            Service.remove({ _id: props.match.params.id })
                .then(result => {
                    props.history.push('/evaluatie');
                })
                .catch(ex => { console.log(ex) })
        }
    }

    const handleCopySurvey = () => {
        if (currentSurvey && item._id) { 
            ServiceSurvey.copyTemplate(currentSurvey, item._id)
                .then(result => {
                    getItems();
                })
                .catch(ex => { })
        }


    }

    useEffect(() => {
        setCanWrite(Service.hasRight(item, context.user, 100));
    }, [item, context.user])

    useEffect(() => {
        getItems();
        getTemplateSurveys();
    }, []);


    useEffect(() => {
        contextUI.setShowBackButton(true);
        if (canWrite) {
            let menu = <Menu id="additemmenu" noscroll={true} control={<IconButton><MoreVert color="white" /></IconButton>}>
                <MenuItem onClick={() => { setShowQuickSearchSurvey(true) }}>Toevoegen vragenlijst</MenuItem>
                {currentSurvey && <MenuItem onClick={() => { handleCopySurvey() }}>Kopieer geselecteerde vragenlijst</MenuItem>}
                <Divider />

            </Menu>
            //                <MenuItem onClick={() => { handleDelete() }}>Verwijder evaluatie</MenuItem>
            //  <MenuItem>Uploaden rapportage</MenuItem>
        //    contextUI.setHeaderActions(menu);
            contextUI.setHeaderActions(<><IconButton onClick={()=>context.setShowFAQ(0)}><Help color="white" /></IconButton>{menu}</>)
        } else { 
            contextUI.setHeaderActions(<><IconButton onClick={()=>context.setShowFAQ(0)}><Help color="white" /></IconButton></>)
        }

        return () => { }
    }, [canWrite, currentSurvey])

    useEffect(() => {
        if (item) {
            contextUI.setSubTitle(item ? item.title : '');
        }

    }, [item])

    const getItems = () => {
        Service.get(props.match.params.id)
            .then(item => {
                setItem(item);

                ServiceSurvey.getForEvaluation(item._id)
                    .then(result => {
                        setSurveys(result)
                    })
                    .catch(err => { console.log(err) })
            })
            .catch(err => { console.log(err) })
    }

    const getTemplateSurveys = () => { ServiceSurvey.getTemplates().then(items => setTemplateSurveys(items.filter(item => { return item.published === true }))).catch(err => { console.log(err) }) }

    const handleSave = (item) => { Service.update(item).then(result => { getItems() }).catch(ex => { console.log(ex) }) }
    const handleSaveSurvey = (item) => { ServiceSurvey.update(item).then(result => { getItems() }).catch(ex => { console.log(ex) }) }

    const createNewSurvey = (survey) => {
        ServiceSurvey.copyTemplate(survey._id, item._id).then(result => {
            getItems();
            setShowQuickSearchSurvey(false);
        }).catch(err => { })
    }

    const selectedStyle = { borderRight: '4px solid rgb(255, 128, 54)', backgroundColor: '#f7f7f7' }


    /*
       <List>
                    <ListItem  style={currentView==='dashboard' ? selectedStyle:null} onClick={() => { setCurrentView('dashboard'); setCurrentSurvey(); }}><ListItemIcon><ViewList /></ListItemIcon><ListItemText primary="Dashboard"></ListItemText></ListItem>
                    <ListItem  style={currentView==='settings' ? selectedStyle:null} onClick={() => { setCurrentView('settings'); setCurrentSurvey(); }}><ListItemIcon><Settings /></ListItemIcon><ListItemText primary="Instellingen"></ListItemText></ListItem>
                    {surveys.map(survey => {
                        return <ListItem key={survey._id} style={currentSurvey===survey._id ? selectedStyle:null} onClick={() => { setCurrentView('survey'); setCurrentSurvey(survey._id) }}><ListItemIcon><CheckCircle /></ListItemIcon> <ListItemText primary={survey.title}></ListItemText> </ListItem>
                    })}
                </List>

                */
    return (
        <Container >
            <SideMenu width={hideMenu ? 40 : 350}>
                <br />

                <div style={styleMenu} onClick={() => { setCurrentView('dashboard'); setCurrentSurvey(); }} >
                    <div style={{ paddingRight: '10px' }}>
                        <ViewList color="333333" />
                    </div>
                    <div style={{ width: '90%' }}>Dashboard </div>
                    <div></div>
                </div>

                <div style={styleMenu} onClick={() => { setCurrentView('settings'); setCurrentSurvey(); }} >
                    <div style={{ paddingRight: '10px' }}>
                        <Settings color="333333" />
                    </div>
                    <div style={{ width: '90%' }}>Instellingen </div>
                    <div></div>
                </div>

                {surveys.map(survey => {
                    return <div key={survey._id} style={styleMenu} onClick={() => { setCurrentView('survey'); setCurrentSurvey(survey._id); }} >
                        <div style={{ paddingRight: '10px' }}>
                            <CheckCircle color="333333" />
                        </div>
                        <div style={{ width: '90%' }}>{survey.title} </div>
                        <div><CirculairProgress progress={survey.progress || 0} /></div>
                    </div>
                })}



            </SideMenu>

            <Content>
                {showQuickSearchSurvey && <QuickSearchSurvey items={templateSurveys} onClick={createNewSurvey} onClose={() => { setShowQuickSearchSurvey(false) }} />}

                {item && currentView === 'dashboard' && <DashView evaluation={item} surveys={surveys} />}
                {item && currentView === 'settings' && <Item history={props.history} item={item} readOnly={!canWrite} onSave={handleSave} />}

                {item && currentView === 'survey' && surveys.map(survey => { if (currentSurvey === survey._id) { return <SurveyView key={survey._id} survey={survey} evaluation={item} onSave={handleSaveSurvey} /> } else { return null } })}
            </Content>
        </Container>

    )
}
