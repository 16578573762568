import React, { useState, useEffect } from 'react'

import { Typography, TextArea, TextField, IconButton, RadioButton, CheckBox, Button, Fab } from '../../ui/core';
import { NoteAdd } from '../../ui/coreIcons';
import './inputform.css';

import Uploaddocument from '../../general/uploaddocument';
import Documents from '../../general/documents';
import ServiceDocument from '../../../services/document';
export default function InputFormControl({ question, respondent, getValue, getValueAttribute, getSavedAnswers, onChange, onChangeAttribute, onChangeCheck, markForSaving, readOnly }) {
    const [showUpload, setShowUpload] = useState(false);
    const [documents, setDocuments] = useState([])
    const handleNewDocument = (document) => {
        document.module = 'respondent_question_image';
        document.moduleid = respondent._id + '-' + question._id;
        ServiceDocument.insert(document)
            .then(result => {
                let value = getValueAttribute(question, 'images');
                if (!value) { value = [] }
                value.push(result._id);
                onChangeAttribute(question, 'images', value, true);
                setShowUpload(false);
                getDocuments();
            })
            .catch(ex => { })
    }
    const handleDeleteDocument = (document) => {
        ServiceDocument.remove(document)
            .then(result => {
                let value = getValueAttribute(question, 'images');
                if (!value) { value = [] }
                value.push(result._id);
                for (let i = value.length - 1; i >= 0; i--) {
                    if (value[i] === document._id) {
                        value.splice(i, 1);
                    }
                }
                onChangeAttribute(question, 'images', value, true);
                getDocuments();
            })
            .catch(ex => { console.log(ex) })
    }

    const getDocuments = () => {
        ServiceDocument.getForModule('respondent_question_image', respondent._id + '-' + question._id)
            .then(result => {
                setDocuments(result);
            })
    }

    useEffect(() => {
        if (respondent && question) {
            getDocuments();
        }
    }, [respondent, question])

    const getControlQuestion = (question) => {
        return <div className="inputform-title">{question.question}</div>
    }
    let retControl = [];

    if (question.type === 0) {
        retControl.push(<div key={'title_' + question._id} style={{ whiteSpace: 'pre-line' }}>{question.text}<br /><br /></div>)
    }
    if (question.type === 1) {
        //   let valAns = getValue(question) ;
        const valAns = getSavedAnswers(question);
        retControl.push(<div key={'title_' + question._id}>{getControlQuestion(question)}<TextField id="question" value={valAns ? valAns.value : ''} onChange={(ev) => onChange(question, ev.target.value)} onBlur={() => { markForSaving(question) }} readOnly={readOnly} /></div>);
        if (valAns && valAns.saved !== true) {
            retControl.push(<div key={"check_saved_opentext_" + question._id} className="showDelayed"><b>⚠️ Let op: antwoord is nog niet op de server opgeslagen ⚠️ </b></div>)

        }
    }
    if (question.type === 2) {
        const valAns = getSavedAnswers(question);
        retControl.push(<div key={'title_' + question._id}>{getControlQuestion(question)}<TextArea id="question" value={valAns ? valAns.value : ''} onChange={(ev) => onChange(question, ev.target.value)} onBlur={() => { markForSaving(question) }} readOnly={readOnly} /></div>);
        if (valAns && valAns.saved !== true) {
            retControl.push(<div key={"check_saved_opentext_" + question._id} className="showDelayed"><b>⚠️ Let op: antwoord is nog niet op de server opgeslagen ⚠️ </b></div>)

        }
    }
    if (question.type === 3) {
        retControl.push(<div key={'title_' + question._id}>{getControlQuestion(question)}</div>);

        if (question.options && question.options.filter) {
            let savedAnswers = [];
            let tmpOptions = question.options.filter(question => { return question.active === true }).map((option) => {
                const val = getValue(question, option);
                try {
                    const valAns = getSavedAnswers(question, option);
                    if (val && valAns && valAns.saved !== true) {
                        savedAnswers.push(valAns);
                    }
                } catch (ex) {
                    console.log(ex);
                }

                return <div key={'opt_' + option._id}>
                    <RadioButton key={'opt_' + option._id} id={'opt_' + option._id} label={option.text || ''} checked={val} onChange={(ev) => onChangeCheck(question, option, ev.target.checked)} readOnly={readOnly} disabled={readOnly} />
                    {val && option.feedback && <div className="optionfeedback">{option.feedback}</div>}
                </div>
            })
            retControl.push(tmpOptions);
            if (savedAnswers.length > 0) {
                retControl.push(<div key={"check_saved_" + question._id} className="showDelayed"><b>⚠️ Let op: antwoord is nog niet op de server opgeslagen ⚠️ </b></div>)
            }

        }
    }
    if (question.type === 4) {
        let savedAnswers = [];
        retControl.push(<div key={'title_' + question._id}>{getControlQuestion(question)}</div>);
        if (question.options && question.options.filter) {
            let tmpOptions = question.options.filter(question => { return question.active === true }).map((option) => {
                const val = getValue(question, option);
                try {
                    const valAns = getSavedAnswers(question, option);
                    if (val && valAns && valAns.saved !== true) {
                        savedAnswers.push(valAns);
                    }
                } catch (ex) {
                    console.log(ex)
                }

                return <div key={'opt_' + option._id}>
                    <CheckBox key={'opt_' + option._id} id={'opt_' + option._id} label={option.text || ''} checked={getValue(question, option)} onChange={(ev) => onChangeCheck(question, option, ev.target.checked)} readOnly={readOnly} disabled={readOnly} />
                    {val && option.feedback && <div className="optionfeedback">{option.feedback}</div>}
                </div>
            })
            retControl.push(tmpOptions);
            if (savedAnswers.length > 0) {
                retControl.push(<div key={"check_saved_" + question._id} className="showDelayed"><b>⚠️ Let op: antwoord is nog niet op de server opgeslagen ⚠️ </b></div>)
            }
        }
    }
    if (question.hasOpenText) {
        retControl.push(<div key={'open_text_' + question._id}><TextArea id="openText" label="waarneming" value={getValueAttribute(question, 'openText') || ''} onChange={(ev) => onChangeAttribute(question, ev.target.id, ev.target.value)} onBlur={() => { markForSaving(question) }} /></div>)
        const valAns = getSavedAnswers(question);

        if (valAns && valAns.saved !== true && valAns.openText) {
            retControl.push(<div key={"check_saved_opentext_" + question._id} className="showDelayed"><b>⚠️ Let op: antwoord is nog niet op de server opgeslagen ⚠️ </b></div>)

        }
    }
    if (question.hasImageUpload) {
        retControl.push(<div key={'image_upload_' + question._id}>
            {showUpload && <Uploaddocument module={'respondent_image'} onSave={(item) => { handleNewDocument(item) }} onClose={() => { setShowUpload(false) }} />}

        </div>)
    }
    return <div key={question._id}>
        {retControl}
        {<Documents documents={documents} onDelete={readOnly ? handleDeleteDocument : null} />}
        {question.hasImageUpload && !readOnly && <Fab onClick={() => { setShowUpload(true) }} style={{ float: 'right' }}><NoteAdd color={'white'} /></Fab>}
        <br style={{ clear: 'both' }} />
    </div>;
}
