import React, { useState, useContext, useEffect } from 'react'
import Service from '../../../services/organisation';
import ServiceUser from '../../../services/user';
import ServiceDepartment from '../../../services/department';
import Item from '../../../components/organisations/item';
import SearchUser from '../../../components/users/quicksearch';
import Parameters from '../../../components/organisations/parameters';
import Tools from '../../../components/organisations/tools';
import { Tabs, Fab, Paper, IconButton } from '../../../components/ui/core';
import { Add } from '../../../components/ui/coreIcons';
import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';

import ListUsers from '../../../components/users/list';
import ListDepartments from '../../../components/departments/list';

export default function OrganisationItem(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [item, setItem] = useState();
    const [selectedTab, setSelectedTab] = useState(0)
    const [users, setUsers] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [showSearchUser, setShowSearchUser] = useState(false);


    const addDepartment = () => {
        let newItem = {};
        newItem.organisation = item._id;
        newItem.name = "";
        newItem.active=true;
        ServiceDepartment.insert(newItem)
            .then(newDepartment => {
                console.log(newDepartment)
                props.history.push('/beheer/departments/' + newDepartment._id)
            })
            .catch(err => {

            })
    }


    useEffect(() => {
        getItems();
        contextUI.setSubTitle('Organisatie');
        contextUI.setShowBackButton(true);
        contextUI.setHeaderActions();
        if (selectedTab === 1) {
            contextUI.setHeaderActions(<IconButton onClick={() => { addDepartment() }}><Add color="white" /></IconButton>)
        }
        return () => { }
    }, [selectedTab])

    const getItems = () => {
        Service.get(props.match.params.id)
            .then(item => {
                setItem(item)
            })
            .catch(err => { console.log(err) })

        ServiceUser.get()
            .then(result => {
                setAllUsers(result)
            })
            .catch(ex => { console.log(ex) })

        ServiceUser.getForOrganisation(props.match.params.id)
            .then(result => {
                setUsers(result);
            })
            .catch(ex => { console.log(ex) })

        ServiceDepartment.getForOrganisation(props.match.params.id)
            .then((result => {
                setDepartments(result)
            }))
            .catch(ex => { console.log(ex) })
    }

    const handleSave = (item) => { Service.update(item).then(result => { getItems() }).catch(ex => { console.log(ex) }) }

    const onAddUser = (user) => {
        console.log(user);
        let updateItem = {};
        updateItem._id = user._id;
        updateItem.organisations = user.organisations || [];
        if (updateItem.organisations.indexOf(item._id) == -1) {
            updateItem.organisations.push(item._id)
        }
        ServiceUser.update(updateItem).then(result => {
            getItems();
        }).catch(ex => { })
        setShowSearchUser(false);
    }

    const handleClickUser = (user) => {
        props.history.push('/beheer/gebruikers/' + user._id)
    }

    const handleClickDepartment = (department) => {
        props.history.push('/beheer/departments/' + department._id)
    }
    /*
    
            {showSearchUser && <SearchUser title="Toevoegen gebruiker" onClick={onAddUser} items={allUsers} onClose={() => { setShowSearchUser(false) }} />}
            <div className="padding"><Paper><ListUsers items={users} onClick={handleClickUser} /></Paper></div>
            <Fab float={true} onClick={() => { setShowSearchUser(true) }}><Add color="white" /></Fab>
             */


    if (!context.hasRights(context.user, 'fullcontrol-organisations', 'w')) return null;

    return (<>
        <Paper>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="padding">ALGEMEEN</div>
                <div className="padding">LOCATIES</div>
                <div className="padding">MEDEWERKERS</div>
                <div className="padding">PARAMETERS</div>
                <div className="padding">TOOLS</div>
            </Tabs></Paper>
        {selectedTab === 0 && <Item item={item} onSave={handleSave} />}
        {selectedTab === 1 && <>
            <div className="padding"><Paper><ListDepartments items={departments} onClick={handleClickDepartment} /></Paper></div>

        </>}
        {selectedTab === 2 && <>
            <div className="padding"><Paper><ListUsers items={users} onClick={handleClickUser} /></Paper></div>

        </>}
        {selectedTab === 3 && <Parameters item={item} onSave={handleSave} />}
        {selectedTab === 4 && <Tools item={item} onSave={handleSave} />}
    </>)
}
