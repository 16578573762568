import React, { useState, useContext, useEffect } from 'react'
import Service from '../../../services/user';
import Item from '../../../components/users/item';
import Access from '../../../components/users/access';
import Organisations from '../../../components/users/organisations';
import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';
import { Paper, Tabs } from '../../../components/ui/core';

export default function OrganisationItem(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [selectedTab, setSelectedTab] = useState(0)
    const [item, setItem] = useState();

    //INITIAL FUNCTIONS
    useEffect(() => {
        getItem();
        contextUI.setSubTitle('Gebruiker ' + Service.getFullName(item));
        contextUI.setShowBackButton(true);
        contextUI.setHeaderActions();
    }, [])

    useEffect(() => {
        contextUI.setSubTitle('Gebruiker ' + Service.getFullName(item));

    }, [item])
    const getItem = () => { Service.get(props.match.params.id).then(item => setItem(item)).catch(err => { console.log(err) }) }

    const handleSave = (item) => { Service.update(item).then(result => { }).catch(ex => { console.log(ex) }) }
    const handleChange = (item) => { setItem(item) }

    if(!context.hasRights(context.user, 'fullcontrol-users', 'w')) return null;

    
    return (<>
        <Paper>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                <div className="padding">ALGEMEEN</div>
                <div className="padding">ORGANISATIES</div>
                <div className="padding">TOEGANG</div>
            </Tabs></Paper>
        {selectedTab === 0 && <Item item={item} onChange={handleChange} onSave={handleSave} canDelete={true} />}
        {selectedTab === 1 && <Organisations item={item} onChange={handleChange}  onSave={handleSave} />}
        {selectedTab === 2 && <Access item={item} onChange={handleChange}  onSave={handleSave} />}
    </>)
}
