
import { Plugins, FilesystemDirectory, FilesystemEncoding } from '../plugins/capacitor';
import FileService from '../services/file';
import Settings from '../AppSettings';
import mime from 'mime-types';
const locationAPI = Settings.getLocationAPI();

const Service = {
   
}
export default Service;