import React, { useState, useContext, useEffect } from 'react'

import Service from '../../../services/organisation';
import GeneralService from '../../../services/general';

import List from '../../../components/organisations/list';

import { ContextUI } from '../../../uiContext';
import { Context } from '../../../AppContext';
import { IconButton,SearchBar,Paper,Tabs } from '../../../components/ui/core';
import { Add } from '../../../components/ui/coreIcons';

export default function Index(props) {
    const contextUI = useContext(ContextUI);
    const context = useContext(Context);
    const [selectedTab,setSelectedTab] = useState(0);
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState([]);

    const [search,setSearch] = useState('');

    //INITIAL SETUP
    useEffect(() => {
        getItems();
        contextUI.setSubTitle('Organisaties');
        contextUI.setShowBackButton(false);
        contextUI.setHeaderActions(<IconButton onClick={addItem}><Add color="white" /></IconButton>)
        return () => { }
    }, [])

    useEffect(() => {
        setItemsFiltered(items.filter(i=>{return JSON.stringify(i).toLowerCase().indexOf(search.toLowerCase())>=0}));
    }, [search])

    useEffect(() => {
        setItemsFiltered(items);
        setSearch('');
    }, [items])


    const getItems = () => { Service.get().then(items => setItems(GeneralService.sortJSON(items, 'name', '123'))).catch(err => { console.log(err) }) }

    const addItem = () => { Service.insert({}).then(item => { viewItem(item._id); }).catch(ex => { console.log(ex) }) }

    const viewItem = (id) => {  props.history.push('/beheer/organisaties/' + id)  }
    
    if(!context.hasRights(context.user, 'fullcontrol-organisations', 'w')) return null;

    return (
        <div>
           <Paper>
            <Tabs value={selectedTab} onChange={setSelectedTab} >
                <div className="ui-tab">ACTUEEL</div>
                <div className="ui-tab">ARCHIEF</div>
              </Tabs>
            </Paper>

             <SearchBar value={search} onKeyUp={setSearch} onReset={()=>{setSearch('')}}></SearchBar>
           
            <List items={itemsFiltered.filter(item=>{if(selectedTab===0 && item.archived !== true){ return true;  }else if (selectedTab==1 && item.archived===true){ return true; }else{return false;}})} onClick={(item) => viewItem(item._id)} />
        </div>
    )
}
