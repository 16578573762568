import React,{useEffect,useState} from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextArea, Typography } from '../../ui/core'
import Settings from "../../../AppSettings";
import ServiceSettings from '../../../services/settings';
const locationAPI = Settings.getLocationAPI();
export default function Edit({ respondent,onUpdate, onClose }) {
    const [currentRespondent,setCurrentRespondent] = useState();

    const getURL = (respondent)=>{
        if(respondent){
            return 'https://apps.scherpinveiligheid.nl/#/vragenlijst/'+ respondent._id + '-' + respondent.code + '-' + respondent.survey
        }else{
            return '';
        }
      
    }

    
    useEffect(() => {
        if(respondent){
            if(!respondent.mailMessage){
                

                ServiceSettings
                .get()
                .then(settings=>{
                    respondent.mailMessage = settings.mailsurveyrespondent || '';
                    setCurrentRespondent(respondent) 
                })
                .catch(ex=>{
                    console.log(ex);
                })
            }else{
                setCurrentRespondent(respondent) ;
            }
           
            /*
            QRCode.toDataURL('https://apps.scherpinveiligheid.nl/#/vragenlijst/'+getLinkParams(respondent), { errorCorrectionLevel: 'Q' }, function (err, url) {
                console.log(url)
                setQRCodeImage(url)
              })
              */
        }
      
    }, [respondent])

    const handleChangeMailMessage = (mailMessage)=>{
        const tmpRespondent = Object.assign({},currentRespondent);
        tmpRespondent.mailMessage = mailMessage;
        setCurrentRespondent(tmpRespondent);
        
    }

    const handleClose = (sendMailMessage) =>{
        const tmpRespondent = Object.assign({},currentRespondent);
        if(sendMailMessage){
            tmpRespondent.sendMail = true;
            tmpRespondent.url = getURL(respondent);         
        }       
        onUpdate(tmpRespondent);
    }

    if(!currentRespondent) return null;

    return (
        <Dialog fullScreen>
            <DialogTitle>     
                <Typography component="h1">Waarnemer {respondent.name}</Typography>   
                </DialogTitle>
            <DialogContent>
      
            <Typography component="h3">Gegevens invoeren vragenlijst</Typography>
                Opvragen en invoeren vragenlijst mogelijk via: <br/><br/>
                <b>URL:</b> <br/><a href={getURL(respondent)} target="_blank">{getURL(respondent)}</a><br/>
               <b>QRCode:</b><br/>
               <img src={locationAPI+'qrcode?url='+ encodeURIComponent(getURL(respondent))}/>
                <Typography component="h3">Uitnodigen per mailbericht</Typography>
                <b>Aan : </b>{respondent.email}<br />
                <TextArea id="mailmessage" label="Mailbericht" value={currentRespondent.mailMessage} onChange={(ev=>{handleChangeMailMessage(ev.target.value)})}></TextArea>
        <div className="label">Gebruik de tags [url] en [qrcode] in het mailbericht om automatisch de juiste waarde in te vullen.</div>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>handleClose()}>Sluiten</Button>
                <Button onClick={()=>handleClose(true)}>Verstuur uitnodiging</Button>
            </DialogActions>
        </Dialog>
    )
}
