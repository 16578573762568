import React, { useContext, useState, useEffect } from 'react'
import ServiceUsers from '../services/user';
import { Paper, Typography, TextField, Button } from '../components/ui/core';
import { Context } from '../AppContext';
import { ContextUI } from '../uiContext';
import ScherpLogo from '../graphics/scherp.svg'
import { ChevronLeft, ChevronRight } from '../components/ui/coreIcons';
import SelectOrganisation from '../components/organisations/selector';
import ImageCheckList from '../graphics/checklist.svg';
import ImageGea from '../graphics/gea.svg'
import ImageBeheer from '../graphics/beheer.svg';
import ImageElearning from '../graphics/elearning.svg';

import UserService from '../services/user';
export default function Select(props) {
    const context = useContext(Context);
    const contextUI = useContext(ContextUI);
    const [organisations, setOrganisations] = useState([])

    useEffect(() => {

        contextUI.setShowHeader(false);
        if(UserService.getToken() === '0'){
            context.logout();
        }
    }, [])


    useEffect(() => {
        ServiceUsers.currentuserOrganisations()
            .then(organisations => {
                setOrganisations(organisations);
                if (!context.organisation && organisations.length === 1) {
                    context.setOrganisation(organisations[0]);
                }
            })
            .catch(err => { contextUI.setMessage('Kan momenteel de gegevens niet ophalen, probeer het later opnieuw') })
    }, [])

    const hasTool = (tool) => {
        if(!context.organisation){
            return false;
        }
        if(!context.organisation.tools){
            return false;
        }
       return (context.organisation.tools.indexOf(tool) >= 0)
        /*
        for (let i = 0; i < organisations.length; i++) {
            console.log(context.organisation.tools.indexOf(tool) >= 0)
            if (organisations[i].tools && organisations[i].tools.indexOf(tool) >= 0) {
                return true;
            }
        }
        return false;
        */
    }

    const boxStyle = { width: '300px', boxSizing: 'border-box', float: 'left', minHeight: '250px', margin: '20px', cursor: 'pointer' }
    const buttonStyle = { display: 'flex', float: 'right', color: 'white', backgroundColor: 'rgba(0, 106, 142,0.58)', clear: 'both', fontWeight: 'bold', padding: '8px', textAlign: 'top' };

    return (
        <div >
            <div style={{ width: '100%', backgroundColor: 'white' }}>
                <img src={ScherpLogo} alt="Scherp" style={{ height: '60px', width: 'auto', padding: '23px' }} />
            </div>
            <div style={{ backgroundColor: '#006a8e', height: '70px', width: '100%' }}>&nbsp;</div>
            <div >
                <SelectOrganisation />
                <br style={{ clear: 'both' }} />
                {!context.organisation && <Paper style={{ ...boxStyle, width: '80%', minHeight: '20px', backgroundColor: '#00698f' }}>
                    <div style={{ padding: '10px' }}>
                        <Typography component={'p'} style={{ textAlign: 'center', color: 'white' }}>Er is nog geen organisatie gekoppeld aan uw account. Neem contact op met een medewerker van Scherp om uw organisatie te koppelen.</Typography>
                    </div>
                </Paper>}

                <Paper style={{ ...boxStyle, backgroundColor: '#00698f' }} >
                    <div style={{ minHeight: '250px', padding: '10px' }}>
                        <Typography component={'h2'} style={{ textAlign: 'center', color: 'white' }}>Scherp in veiligheid</Typography>
                        <Typography component={'p'} style={{ textAlign: 'center', color: 'white' }}>
                            Hier staan de online tools van Scherp. Selecteer een tool om ermee te starten.<br /><br />
                        Vragen? Neem gerust contact met ons op.<br /><br />
                        Tel:  031850161357<br />
                        Email: info@scherpinveiligheid.nl<br />
                        </Typography>
                    </div>
                </Paper>
                {context.organisation &&
                    <Paper style={{...boxStyle,opacity:hasTool('elearning')?'1':'0.5'}} onClick={() => { if (hasTool('elearning')) { context.setModule('e-learning'); window.location.href = '/#/e-learning' } }}>
                        <img src={ImageElearning} />
                        <div style={{ minHeight: '340px', padding: '10px' }}>
                            <Typography component={'h2'}  >E-learning</Typography>
                            <Typography component={'p'}  >
                                {context.settings.introelearning || ''}
                            </Typography>
                        </div>
                        {hasTool('elearning') && <div style={buttonStyle}><div style={{ paddingTop: '2px' }}>Bekijk</div> <ChevronRight color="white" /></div>}
                    </Paper>}

                {context.organisation &&
                    <Paper style={{...boxStyle,opacity:hasTool('evaluation')?'1':'0.5'}} onClick={() => { if (hasTool('evaluation')) { context.setModule('evaluatie'); window.location.href = '/#/evaluatie' } }}>
                        <img src={ImageGea} />
                        <div style={{ minHeight: '340px', padding: '10px' }}>
                            <Typography component={'h2'}  >Evaluatie app</Typography>
                            <Typography component={'p'} style={{ whiteSpace: 'pre-wrap' }} >
                                {context.settings.introgea || ''}
                            </Typography>
                        </div>
                        {hasTool('evaluation') && <div style={buttonStyle}><div style={{ paddingTop: '2px' }}>Bekijk</div> <ChevronRight color="white" /></div>}
                    </Paper>}

                {context.organisation &&
                    <Paper style={{...boxStyle,opacity:hasTool('checklist')?'1':'0.5'}} onClick={() => { if (hasTool('checklist')) { context.setModule('checklist'); window.location.href = '/#/checklist' } }}>
                        <img src={ImageCheckList} />
                        <div style={{ minHeight: '340px', padding: '10px' }}>
                            <Typography component={'h2'}  >Bevoegdheden applicatie</Typography>
                            <Typography component={'p'} style={{ whiteSpace: 'pre-wrap' }} >
                                {context.settings.introchecklist || ''}
                            </Typography>

                        </div>

                        {hasTool('checklist') && <div style={buttonStyle}><div style={{ paddingTop: '2px' }}>Bekijk</div> <ChevronRight color="white" /></div>}
                    </Paper>}

                    {context.organisation && UserService.canWrite(context.user.rights,'organisations',context.organisation._id) &&
                    <Paper style={boxStyle} onClick={() => { context.setModule('organisation'); window.location.href = '/#/organisatie'  }}>
                       <img src={ImageBeheer} />
                        <div style={{ minHeight: '340px', padding: '10px' }}>
                            <Typography component={'h2'}  >Beheer </Typography>
                            <Typography component={'p'} style={{ whiteSpace: 'pre-wrap' }} >
                                Locatie en gebruikersbeheer {context.organisation.name}
                            </Typography>
                        </div>
                        <div style={buttonStyle}><div style={{ paddingTop: '2px' }}>Bekijk</div> <ChevronRight color="white" /></div>
                    </Paper>}


                {(context.hasRights(context.user, 'fullcontrol-organisations', 'w') || context.hasRights(context.user, 'fullcontrol-users', 'w') || context.hasRights(context.user, 'fullcontrol-surveys', 'w') || context.hasRights(context.user, 'fullcontrol-elearnings', 'w') || context.hasRights(context.user, 'fullcontrol-checklists', 'w')) &&
                    <Paper style={boxStyle} onClick={() => { context.setModule('beheer'); window.location.href = '/#/beheer' }}>
                        <img src={ImageBeheer} />
                        <div style={{ minHeight: '340px', padding: '10px' }}>
                            <Typography component={'h2'}  >Beheer</Typography>
                            <Typography component={'p'} style={{ whiteSpace: 'pre-wrap' }} >
                                Beheer tool voor de Scherp medewerker
                             </Typography>
                        </div>
                        <div style={buttonStyle}><div style={{ paddingTop: '2px' }}>Bekijk</div> <ChevronRight color="white" /></div>

                    </Paper>}


                
            </div>
        </div>
    )
}
