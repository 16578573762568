import React, { useState, useContext } from 'react'
import { Paper, Tabs, Typography } from '../ui/core'
import SurveyBuilder from '../module-survey/builder/index';
import RespondentBuilder from '../module-survey/builder-respondents/index';
import Overview from '../module-survey/overview/index';
import Settings from '../module-survey/settings/index';
import Rights from '../module-survey/rights/index';
import Service from '../../services/survey';
import ServiceEvaluation from '../../services/evaluations';
import { Context } from '../../AppContext';
export default function SurveyView({ survey,evaluation, onSave }) {
    const context = useContext(Context);
    const [selectedTab, setSelectedTab] = useState(0);

    if(!ServiceEvaluation.hasRight(evaluation, context.user, 10)){
        return <></>
    }
    //  {<div id={4} className="padding">BETROKKENEN</div>}
    //  {selectedTab === 4 && <Rights survey={survey} onSave={onSave} />}
    return (
        <>
       <Typography component="h2" className="padding">{survey.title}</Typography>
            <Tabs value={selectedTab} onChange={setSelectedTab}>
                {<div id={0} className="padding">OVERZICHT</div>}
                {ServiceEvaluation.hasRight(evaluation, context.user, 100) && <div  id={1} className="padding">WAARNEMERS</div>}
              
                {ServiceEvaluation.hasRight(evaluation, context.user, 100) && <div  id={2} className="padding">VRAGENLIJST</div>}
                {ServiceEvaluation.hasRight(evaluation, context.user, 100) && <div id={3} className="padding">INSTELLINGEN</div>}
               
            </Tabs>
          
        <div className="padding">
            {selectedTab === 0 && <Overview survey={survey} />}
            {selectedTab === 1 &&  <RespondentBuilder survey={survey}  />}
            {selectedTab === 2 && ServiceEvaluation.hasRight(evaluation, context.user, 100) && <SurveyBuilder survey={survey} onSave={onSave} />}
            {selectedTab === 3 && ServiceEvaluation.hasRight(evaluation, context.user, 100) && <Settings survey={survey} onSave={onSave} />}
          
        </div>
        </>
    )
}
