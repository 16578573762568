import React, { useContext, useEffect, useState } from 'react'
import { HashRouter, Route } from "react-router-dom";

import DialogFAQ from './components/faq/dialog'

import PageWrapper from './components/general/pagewrapper';
import Login from './components/users/login/login';
import SelectModule from './pages/moduleSelect';
import Index from './pages/index';

import IndexElearning from './pages/elearning/index';
import IndexElearningItem from './pages/elearning/item';

import IndexEvaluatie from './pages/evaluation/index';
import IndexEvaluatieItem from './pages/evaluation/item';

import IndexCheckList from './pages/checklistcase/index';
import IndexCheckListItem from './pages/checklistcase/item';

import IndexBeheer from './pages/beheer/index';
import IndexBeheerOrganisations from './pages/beheer/organisations/index'
import IndexBeheerOrganisationsItem from './pages/beheer/organisations/item'

import IndexBeheerDepartmentsItem from './pages/beheer/departments/item'

import IndexBeheerUsers from './pages/beheer/users/index';
import IndexBeheerUsersItem from './pages/beheer/users/item'

import IndexBeheerSurveys from './pages/beheer/surveys/index';
import IndexBeheerSurveysItem from './pages/beheer/surveys/item';

import IndexSurvey from './pages/survey/survey';

import IndexBeheerElearnings from './pages/beheer/e-learnings/index';
import IndexBeheerElearningsItem from './pages/beheer/e-learnings/item';

import IndexBeheerElearningLesson from './pages/beheer/e-learnings/builder-lesson';
import IndexBeheerElearningQuiz from './pages/beheer/e-learnings/builder-quiz';

import IndexBeheerCheckLists from './pages/beheer/checklists/index';
import IndexBeheerCheckListsItem from './pages/beheer/checklists/item';


import IndexBeheerSettings from './pages/beheer/settings/index';

import IndexOrganisation from './pages/organisation';
import IndexOrganisationDepartments from './pages/organisation/department';
import IndexOrganisationUsers from './pages/organisation/user';

import MenuElearning from './components/module-elearning/menu';
import MenuEvaluatie from './components/module-survey/menu';
import MenuChecklist from './components/module-checklist/menu';
import MenuBeheer from './components/module-beheer/menu';
import MenuOrganisation from './components/organisations/menu'


import { ContextUI } from './uiContext';
import { Context } from './AppContext';

export default function Router(props) {
        const contextUI = useContext(ContextUI);
        const context = useContext(Context);

        const [refresh, setRefresh] = useState();

      
        //INITIALIZE UI
        useEffect(() => {
                if (context.module) {
                        contextUI.setShowHeader(true);
                        contextUI.setTitle(context.module.toUpperCase());
                        if(context.module==='checklist'){  contextUI.setTitle("Bevoegdheden applicatie")}
                        switch (context.module) {
                                case 'evaluatie':
                                        contextUI.setSideMenu(<MenuEvaluatie />);
                                        break;
                                case 'e-learning':
                                        contextUI.setSideMenu(<MenuElearning />);
                                        break;
                                case 'checklist':
                                        contextUI.setSideMenu(<MenuChecklist />);
                                        break;
                                      
                                case 'beheer':
                                        contextUI.setSideMenu(<MenuBeheer />);
                                        break;
                                case 'organisation':
                                        contextUI.setSideMenu(<MenuOrganisation />);
                                        break;
                                default:
                                        break;
                        }
                } else {
                        contextUI.setShowHeader(false);
                        contextUI.setSideMenu();
                }
                contextUI.setPrimary({ color: "#00698f", contrast: '#fff' })
                contextUI.setSecondary({ color: "#00698f", contrast: '#fff' })

                //     headersIcons.push(<IconButton key={'searchbutton'} onClick={() => { context.history && context.history.push('/search') }}> <SearchIcon color="white" /> </IconButton>);
                //     contextUI.setHeaderActions(headersIcons)
                if (context.user === undefined) {
                        contextUI.setShowHeader(false);
                        contextUI.setSideMenu();
                }
               
        }, [refresh, context.module, context.user])

        if (!context.user && window.location.href.indexOf('/#/vragenlijst/') === -1) return (<Login />)
               
        return (
                <div style={{ width: "100%" }}>
                          {context.showFAQ !==undefined? <DialogFAQ FAQs={context.FAQs.filter(f=>{return f.module===context.showFAQ})} onClose={()=>{context.setShowFAQ()}}/>:null}
                        <HashRouter path={"/"} Component={Index}>
                                <Route path="/" exact render={props => (<PageWrapper {...props}><SelectModule  {...props} /></PageWrapper>)} />

                                
                                <Route path="/e-learning" exact render={props => (<PageWrapper {...props}><IndexElearning  {...props} /></PageWrapper>)} />
                                <Route path="/e-learning/:id" exact render={props => (<PageWrapper {...props}><IndexElearningItem  {...props} /></PageWrapper>)} />

                                <Route path="/evaluatie" exact render={props => (<PageWrapper {...props}><IndexEvaluatie  {...props} /></PageWrapper>)} />
                                <Route path="/evaluatie/:id" exact render={props => (<PageWrapper {...props}><IndexEvaluatieItem  {...props} /></PageWrapper>)} />


                                <Route path="/checklist" exact render={props => (<PageWrapper {...props}><IndexCheckList {...props} /></PageWrapper>)} />
                                <Route path="/checklist/:id" exact render={props => (<PageWrapper {...props}><IndexCheckListItem {...props} /></PageWrapper>)} />

                                
                                <Route path="/organisatie" exact render={props => (<PageWrapper {...props}><IndexOrganisation {...props} /></PageWrapper>)} />
                                <Route path="/organisatie/locaties/:id" exact render={props => (<PageWrapper {...props}><IndexOrganisationDepartments {...props} /></PageWrapper>)} />
                                <Route path="/organisatie/gebruikers/:id" exact render={props => (<PageWrapper {...props}><IndexOrganisationUsers {...props} /></PageWrapper>)} />
                            
                                <Route path="/beheer" exact render={props => (<PageWrapper {...props}><IndexBeheer {...props} /></PageWrapper>)} />
                                <Route path="/beheer/organisaties" exact render={props => (<PageWrapper {...props}><IndexBeheerOrganisations {...props} /></PageWrapper>)} />
                                <Route path="/beheer/organisaties/:id" exact render={props => (<PageWrapper {...props}><IndexBeheerOrganisationsItem {...props} /></PageWrapper>)} />

                                <Route path="/beheer/departments/:id" exact render={props => (<PageWrapper {...props}><IndexBeheerDepartmentsItem {...props} /></PageWrapper>)} />


                                <Route path="/beheer/gebruikers" exact render={props => (<PageWrapper {...props}><IndexBeheerUsers {...props} /></PageWrapper>)} />
                                <Route path="/beheer/gebruikers/:id" exact render={props => (<PageWrapper {...props}><IndexBeheerUsersItem {...props} /></PageWrapper>)} />

                                <Route path="/beheer/vragenlijsten" exact render={props => (<PageWrapper {...props}><IndexBeheerSurveys {...props} /></PageWrapper>)} />
                                <Route path="/beheer/vragenlijsten/:id" exact render={props => (<PageWrapper {...props}><IndexBeheerSurveysItem {...props} /></PageWrapper>)} />

                                <Route path="/vragenlijst/:id" exact render={props => (<PageWrapper {...props}><IndexSurvey {...props} /></PageWrapper>)} />


                                <Route path="/beheer/e-learning" exact render={props => (<PageWrapper {...props}><IndexBeheerElearnings {...props} /></PageWrapper>)} />
                                <Route path="/beheer/e-learning/:id" exact render={props => (<PageWrapper {...props}><IndexBeheerElearningsItem {...props} /></PageWrapper>)} />

                                <Route path="/beheer/e-learning/:id/quiz/:stepid" exact render={props => (<PageWrapper {...props}><IndexBeheerElearningQuiz {...props} /></PageWrapper>)} />
                                <Route path="/beheer/e-learning/:id/lessen/:stepid" exact render={props => (<PageWrapper {...props}><IndexBeheerElearningLesson {...props} /></PageWrapper>)} />


                                <Route path="/beheer/checklijsten" exact render={props => (<PageWrapper {...props}><IndexBeheerCheckLists {...props} /></PageWrapper>)} />
                                <Route path="/beheer/checklijsten/:id" exact render={props => (<PageWrapper {...props}><IndexBeheerCheckListsItem {...props} /></PageWrapper>)} />

                                <Route path="/beheer/instellingen" exact render={props => (<PageWrapper {...props}><IndexBeheerSettings {...props} /></PageWrapper>)} />
                                
                        </HashRouter>
                </div>
        )
}