import React, { useEffect, useState } from 'react'
import Service from '../../../services/question';
import GeneralService from '../../../services/general'
import { Button, Menu, MenuItem, ExpansionPanel, Paper } from '../../ui/core';
import Question from './question';
import Import from './import';
export default function Index({ survey ,onSave}) {
    const [questions, setQuestions] = useState([]);
    const [showImport,setShowImport] = useState(false);
    useEffect(() => {
        getQuestions();
    }, [])

    const getQuestions = () => {
        Service.getForSurvey(survey._id)
            .then(result => { setQuestions(GeneralService.sortJSON(result, 'sort', '123')) })
            .catch(err => { console.log(err) })
    }

    const addQuestion = (type) => {
        let maxNumber = 1;
        for (let i = 0; i < questions.length; i++) {
            if (questions[i].sort && questions[i].sort >= maxNumber) {
                maxNumber = parseInt(questions[i].sort) + 1
            }
        }
        Service.insert({ survey: survey._id, question: '', type: type, sort: parseInt(maxNumber) })
            .then(result => {
                getQuestions();
            })
            .catch(ex => { console.log(ex) })
    }

    const handleSave = (item) => { Service.update(item).then(result => { }).catch(ex => { console.log(ex) }) }
    const handleDelete = (item) => { Service.remove(item).then(result => { getQuestions() }).catch(ex => { console.log(ex) }) }
  
    const handleCloseImport = () =>{
        getQuestions();
        setShowImport(false);
    }


    return (
        <div >
            {showImport && <Import survey={survey} onClose={handleCloseImport} onSaveSurvey={onSave}/>}
            {questions.map(question => {
                return <Paper key={'panel' + question._id} className="padding" style={{ marginBottom: '8px' }}><Question key={question._id} mainProcesses={survey.mainProcesses} question={question} onSave={handleSave} onDelete={handleDelete}></Question></Paper>
            })}
            <br />
            <Menu id="questionselectmenu" control={<Button fullWidth variant="outlined" >Vraag toevoegen</Button>}>
                <MenuItem onClick={() => addQuestion(0)}>Tekstblok</MenuItem>
                <MenuItem onClick={() => addQuestion(1)}>Open vraag, enkele regel</MenuItem>
                <MenuItem onClick={() => addQuestion(2)}>Open vraag, meerdere regels</MenuItem>
                <MenuItem onClick={() => addQuestion(3)}>Gesloten vraag,<br /> 1 antwoord mogelijkheid</MenuItem>
                <MenuItem onClick={() => addQuestion(4)}>Gesloten vraag,<br /> meerdere antwoord mogelijkheden</MenuItem>
            </Menu>
            <Button fullWidth variant="outlined" onClick={()=>{setShowImport(true)}}>Vragenlijst importeren</Button>
        </div>
    )
}
