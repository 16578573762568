import React,{useState,useContext,useEffect} from 'react'
import { Context } from '../../AppContext';
import ServiceUsers from '../../services/user';
import ServiceGeneral from '../../services/general';
import SelectOrganisation from './quicksearch';
export default function Selector() {
    const context = useContext(Context);
    const [organisations, setOrganisations] = useState([])

    useEffect(() => {
        ServiceUsers.currentuserOrganisations()
            .then(organisations => { 
                setOrganisations(ServiceGeneral.sortJSON(organisations, 'name', '123'));
                if (!context.organisation && organisations.length === 1) {
                    context.setOrganisation(organisations[0]);
                }
             })
    }, [])

    return (
        < div style={{width:'100%',backgroundColor:'white',color:'black',borderBottom:'1px solid #1e576b'}} >
            {!context.organisation && <SelectOrganisation title="Selecteer de organisatie waar u mee wilt werken" items={organisations} onClick={context.setOrganisation} />}
            <div className="padding">
            {context.organisation && <div style={{ float: 'right', cursor: 'pointer' }} onClick={() => { context.setOrganisation() }}><u>Organisatie: {context.organisation.name}</u></div>}
            </div>
            <br style={{ clear: 'both' }} />
        </div>
    )
}
