import React,{useEffect} from 'react';
//import FirebaseService from "./services/firebase";
import '../node_modules/react-vis/dist/style.css';
import UI from "./uiContext";
import Router from "./AppRouter";
import AppContext from "./AppContext";

function App(props) {
  const startApp = () => {
  //  FirebaseService.initToken();
  //  FirebaseService.setBadge(0);
  }

  useEffect(() => {
    if (!window.cordova) {
      startApp()
    } else {
      document.addEventListener('deviceready', startApp, true)
    }
    
    window.onbeforeunload = function(e) {
      window.focus();
      // Remove focus from any focused element
      if (document.activeElement) {
          document.activeElement.blur();
      } 
    };
  }, [])

  return (
  
    <AppContext>
        <UI  >
        <Router {...props} />
        </UI>
    </AppContext>
  
  );
}

export default App;
